<template>
    <v-container>
        <EditTable title="Variabeln" :reload.sync="reload" :headers="cols" api_load="system/GetSettings" :createfields="create" api_create="system/AddSetting" :editfields="edit" api_edit="system/SetSetting">
            
        </EditTable>
    </v-container>
</template>

<script>
    export default {
        data: function() {
            return {
                cols: [
                    { text: 'ID', value: 'id' },
                    { text: 'Name', value: 'name' },
                    { text: 'Wert', value: 'value' },
                    { text: 'Beschreibung', value: 'description' },
                    { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
                ],
                create: [
                    { text: 'Name', var: 'name', value: null },
                    { text: 'Wert', var: 'value', value: null },
                    { text: 'Beschreibung', var: 'description', value: null },
                ],
                edit: [
                    { text: 'ID', var: 'id', value: null, readonly: true },
                    { text: 'Name', var: 'name', value: null },
                    { text: 'Wert', var: 'value', value: null },
                    { text: 'Beschreibung', var: 'description', value: null },
                ],
            }
        },
        methods: {
 
        },
        created () {
            
        }
    }
</script>

<style scoped>

</style>