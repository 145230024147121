<template>
  <v-container fluid>
    <Box title="Offene Umfragen">
      <p>
        Hier sehen Sie die aktuellen Umfragen, die für Sie zur Beantwortung
        offen sind.
      </p>
      <EditTable
        :reload.sync="reloadSurveys"
        :headers="tableHeadersSurvey"
        api_load="surveys/ListMySurveys/0/0/2"
      >
        <template v-slot:default="item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }"
              ><!-- :disabled="!item.IsActive4User" -->
              <v-btn
                color="primary"
                small
                fab
                @click="startSurvey(item)"
                v-bind="attrs"
                v-on="on"
                :disabled="!item.IsStartable4User && !item.IsActive4User"
                ><v-icon>{{
                  item.IsStartable4User
                    ? "assignment"
                    : item.IsActive4User
                    ? "pending_actions"
                    : "assignment_turned_in"
                }}</v-icon></v-btn
              >
            </template>
            <span>{{
              item.IsActive4User ? "Teilnahme fortsetzen" : "teilnehmen"
            }}</span>
          </v-tooltip>
        </template>
      </EditTable>
      <SurveyAnswerDialog
        v-model="showSurveyAnswerDialog"
        :survey="activeSurvey"
      />
    </Box>
    <Box title="Von Ihnen erstellte Umfragen">
      <p>
        Legen Sie hier eine neue Umfrage für Ihr Team an und legen Sie Inhalte
        sowie Zeitraum der Umfrage fest.
      </p>
      <p>
        In der Übersicht sehen Sie Ihre bereits angelegten Umfragen.
      </p>
      <HelpBox
        :items="[
          'Empfehlung zum Umfang der Umfrage und zur Nutzungshäufigkeit:',
        ]"
      >
        <template slot="0">
          <p>
            Da der TeamCheck ein schnelles Feedback-Tool darstellt, empfehlen
            wir, dass Sie sich bei der Auswahl der Fragemodule auf aktuell
            relevante Aspekte beschränken. Einige Aspekte können sich relativ
            schnell im Arbeitsalltag ändern (z.B. Aspekte der Aufgabe, Aspekte
            der Zeit), was eine regelmäßige Abfrage sinnvoll macht. Andere
            Aspekte (z.B. Vertrauen im Team, Teamidentifikation) bleiben dagegen
            relativ stabil. Daher ist für diese Aspekte eine regelmäßige Abfrage
            weniger sinnvoll.
          </p>
          <p>
            Die Nutzungshäufigkeit ist von Ihrer Teamsituation abhängig. In
            Projektteams, in denen sich viele Aspekte häufig ändern können, kann
            der TeamCheck häufiger verwendet werden (1x pro Monat). In
            Abteilungsteams oder anderen Teams, in denen die Situation relativ
            stabil bleibt, kann der TeamCheck auch viertel- oder halbjährlich
            eingesetzt werden.
          </p>
          <p>
            Generell empfehlen wir einen Umfang von etwa 5-7 Aspekten pro Umfrage. 
            Das bedeutet einen Fragenkatalog von ca. 20 Fragen 
            und eine Bearbeitungszeit von knapp fünf Minuten. 
          </p>
        </template>
      </HelpBox>
      <v-row>
        <v-col>
          <!-- :createfields="create" api_create="questions/CreateCategory" :editfields="edit" api_edit="questions/ChangeCategory" -->
          <EditTable
            @reload="reloadSurveys = true"
            :reload.sync="reload"
            :headers="cols"
            api_load="surveys/ListAdminSurveys"
            sort="statusid"
          >
              <template v-slot:buttonbar>
                  <v-btn color="primary" dark class="ma-2 text-subtitle-2 text-capitalize" @click="create.show = true"><v-icon left>add</v-icon>Neue Umfrage</v-btn>
              </template>
              <template v-slot:default="item">
              <v-tooltip
                bottom
                v-if="
                  item.isAdmin == 1 &&
                    item.statusid == 2 &&
                    item.backToDraftavailable
                ">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    @click="surveyDraft(item)"
                    v-bind="attrs"
                    :loading="actionloading"
                    v-on="on">
                  <v-icon>undo</v-icon></v-btn>
                </template>
                <span>zum Entwurf machen</span>
              </v-tooltip>

              <v-tooltip bottom v-if="item.statusid == 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="green"
                    small
                    fab
                    @click="surveyOpen(item)"
                    v-bind="attrs"
                    v-on="on"
                    :loading="actionloading"
                    ><v-icon>public</v-icon></v-btn
                  >
                </template>
                <span>jetzt aktivieren</span>
              </v-tooltip>

              <v-tooltip bottom v-if="item.statusid == 2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red"
                    small
                    fab
                    @click="surveyCloseDialog(item)"
                    v-bind="attrs"
                    v-on="on"
                    :loading="actionloading"
                    ><v-icon>close</v-icon></v-btn
                  >
                </template>
                <span>abschließen</span>
              </v-tooltip>

              <v-tooltip
                bottom
                v-if="item.statusid == 3 && item.resultsavailable"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    :to="{
                      name: 'results_item',
                      params: { randomId: item.random },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>launch</v-icon></v-btn
                  >
                </template>
                <span>Ergebnisse anzeigen</span>
              </v-tooltip>
            </template>
          </EditTable>
        </v-col>
      </v-row>
      <v-dialog v-model="create.show" max-width="80%">
        <v-card tile v-if="create.show">
          <v-card-title>
            <span>Neue Umfrage erstellen</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="create.show = false">
              <v-icon>clear</v-icon>
            </v-btn>
          </v-card-title>

          <v-stepper v-model="create.step" non-linear>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                editable
                :complete="create.surveyDataValid"
                >Allgemeines</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step
                step="2"
                :editable="create.surveyDataValid"
                :complete="countSelectedCategories > 0"
                >Fragenmodule</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step step="3" :editable="countSelectedCategories > 0"
                >Fertigstellen</v-stepper-step
              >
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <EditForm
                  :editonly="true"
                  :valid.sync="create.surveyDataValid"
                  :fields="create.fields"
                  nobuttons
                />
                <v-row>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      text
                      @click="create.step = 2"
                      right
                      :disabled="!create.surveyDataValid"
                      >Weiter <v-icon>keyboard_arrow_right</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="category in create.categories"
                    :key="category.id"
                  >
                    <v-container>
                      <v-row no-gutters dense justify="center" align="center">
                        <v-col cols="auto">
                          <v-checkbox
                            class="d-inline-flex pl-4 w-auto"
                            :value="categoryFullSelected(category)"
                            :indeterminate="categoryHalfSelected(category)"
                            @change="categorySelectChange($event, category)"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col>
                          <v-expansion-panel-header class="d-inline-flex">
                            <span
                              :class="
                                'font-weight-bold' +
                                  (categoryFullSelected(category)
                                    ? ' green--text'
                                    : '')
                              "
                            >
                              {{ category.name }} ({{
                                category.selected.length
                              }}
                              / {{ categoryItems(category).length }})
                            </span>
                          </v-expansion-panel-header>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel
                          v-for="subcategory in categoryItems(category)"
                          :key="subcategory.id"
                        >
                          <v-container>
                            <v-row
                              no-gutters
                              dense
                              justify="center"
                              align="center"
                            >
                              <v-col cols="auto">
                                <v-checkbox
                                  v-model="category.selected"
                                  @change="$forceUpdate()"
                                  :label="subcategory.name"
                                  :value="subcategory.id"
                                >
                                  <template v-slot:label>
                                    <span class="font-weight-bold">{{
                                      subcategory.name
                                    }}</span>
                                  </template>
                                </v-checkbox>
                              </v-col>
                              <v-col>
                                <v-expansion-panel-header class="d-inline-flex">
                                </v-expansion-panel-header>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-expansion-panel-content>
                            {{ subcategory.description }}
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-row>
                  <v-col cols="auto">
                    <v-btn text @click="create.step = 1"
                      ><v-icon>keyboard_arrow_left</v-icon> Zurück</v-btn
                    >
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      text
                      @click="create.step = 3"
                      :disabled="countSelectedCategories == 0"
                      >Weiter <v-icon>keyboard_arrow_right</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-row v-if="create.step == 3">
                  <v-col cols="4">
                    <Box title="Allgemeine Informationen">
                      <v-text-field
                        v-for="field in create.fields"
                        :key="field.var"
                        :value="
                          field.type == 'select'
                            ? field.loadedData.find(
                                (i) => i[field.select.value] == field.value
                              )[field.select.text]
                            : field.type == 'datetime'
                            ? new Date(field.value * 1000)
                                .toLocaleString()
                                .slice(0, -3)
                            : field.value
                        "
                        :label="field.text"
                        readonly
                        disabled
                      ></v-text-field>
                    </Box>
                  </v-col>
                  <v-col v-if="create.categories.length > 0">
                    <Box title="Fragenmodule">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Kategorie
                              </th>
                              <th class="text-left">
                                Anzahl Fragemodule
                              </th>
                              <th class="text-left">
                                Anzahl Fragen
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template >
                              <!--tr v-for="subcategory in create.subcategories.filter(sc => category.selected.includes(sc.id))" :key="`${category.id}_${subcategory.id}`">
                                                                <td>{{ category.name }}</td>
                                                                <td>{{ subcategory.name }}</td>
                                                                {{category}}
                                                            </tr-->
                              <tr v-for="category in create.categories" :key="category.id">
                                <td>{{ category.name }}</td>
                                <td>
                                  {{ category.selected.length }} /
                                  {{ categoryItems(category).length }}
                                </td>
                                <td>{{ countSelectedQuestions(category) }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </Box>
                    <!--Box title="Teamleitung">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                E-Mail
                                                            </th>
                                                            <th class="text-left">
                                                                Name
                                                            </th>
                                                            <th class="text-left">
                                                                Vorname
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item,i) in create.admins" :key="i">
                                                            <td>{{ item.email }}</td>
                                                            <td>{{ item.lastname }}</td>
                                                            <td>{{ item.firstname }}</td>
                                                        </tr>
                                                    </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </Box-->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto">
                    <v-btn text @click="create.step = 2"
                      ><v-icon>keyboard_arrow_left</v-icon> Zurück</v-btn
                    >
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      v-if="startNow"
                      color="primary"
                      dark
                      class="ma-2 text-subtitle-2 text-capitalize"
                      @click="createSurvey(true)"
                      :loading="create.loading"
                      ><v-icon left>save</v-icon> Speichern & Starten</v-btn
                    >
                    <v-btn
                      v-else
                      color="primary"
                      dark
                      class="ma-2 text-subtitle-2 text-capitalize"
                      @click="createSurvey(false)"
                      :loading="create.loading"
                      ><v-icon left>save</v-icon> Speichern</v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmClose" max-width="33%">
        <v-card>
          <v-card-title class="headline">
            Umfrage wirklich abschließen?
          </v-card-title>
          <v-card-text>
            <p>
              Sind Sie sicher das die Umfrage abgeschlossen werden soll?
            </p>
            <v-alert
              v-show="confirmCloseWarn"
              border="bottom"
              colored-border
              type="warning"
              elevation="2"
            >
              Wenn Sie diese Umfrage abschließen können
              <b>keine Ergebnisse</b> abgerufen werden, da
              <b>weniger als 3 Benutzer teilgenommen</b> haben.
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="surveyClose()">Abschließen</v-btn>
            <v-btn text @click="confirmClose = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </Box>
  </v-container>
</template>

<script>
export default {
  name: "Surveys",
  data: function() {
    return {
      showSurveyAnswerDialog: false,
      activeSurvey: null,
      reloadSurveys: false,
      confirmClose: false,
      confirmCloseId: null,
      confirmCloseWarn: false,
      tableHeadersSurvey: [
        { text: "Umfrage", value: "name", align: "start" },
        { text: "Team", value: "groupname" },
        { text: "Start", value: "startdate", type: "datetime" },
        { text: "Ende", value: "enddate", type: "datetime" },
        { text: "Aktion", sortable: false, value: "actions" },
      ],
      actionloading: false,
      reload: false,
      create: {
        show: false,
        step: 1,
        loading: false,
        surveyDataValid: false,
        fields: {
          0: {
            text: "Team",
            var: "groupid",
            value: null,
            required: true,
            rules: [(v) => !!v || "erforderlich"],
            data: "groups/GetMyGroups",
            filter: (g) => g.isAdmin == 1,
            type: "select",
            select: { text: "name", value: "id" },
          },
          1: {
            text: "Name",
            var: "name",
            value: null,
            required: true,
            rules: [(v) => !!v || "erforderlich"],
          },
          2: {
            text: "Beschreibung",
            var: "description",
            value: '',
            required: false,
          },
          startdate: {
            text: "Startdatum",
            var: "startdate",
            value: null,
            type: "datetime",
            disabled: false,
            datetime: { min: new Date(Date.now()).toISOString() },
          },
          enddate: {
            text: "Enddatum",
            var: "enddate",
            value: null,
            type: "datetime",
            required: true,
            datetime: { min: new Date(Date.now()).toISOString() },
          }, //rules: [ v => this.create.fields.startdate.value < v || 'Enddatum muss hinter de Startdatum liegen' ],
          5: {
            text: "Detailgrad",
            var: "detailsid",
            value: null,
            required: true,
            rules: [(v) => !!v || "erforderlich"],
            data: "surveys/ListLevelsOfDetails",
            type: "select",
            select: { text: "name", value: "id" },
          },
        },
        categories: [],
        subcategories: [],
        companyMembers: [],
        members: [],
        admins: [],
      },
      surveys: [],
      cols: [
        //{ text: 'ID', value: 'id' },
        { text: "Name", value: "name" },
        { text: "Team", value: "groupname" },
        //{ text: 'Beschreibung', value: 'description' },
        { text: "Detailgrad", value: "detail" },
        //{ text: "Erstellt am", value: "createtime", type: "datetime" },
        { text: "Start", value: "startdate", type: "datetime" },
        { text: "Ende", value: "enddate", type: "datetime" },
        { text: "n/max.", value: "userParticipationCount" },
        { text: "Status", value: "status" },
        { text: "Aktionen", align: "end", sortable: false, value: "actions" },
      ],
    };
  },
  computed: {
    countSelectedCategories() {
      var count = 0;
      this.create.categories.forEach((cat) => {
        if (cat != null) {
          count += cat.selected.length;
        }
      });
      return count;
    },
    startNow() {
      return (
        new Date(this.create.fields.startdate.value * 1000) <=
        new Date(Date.now())
      );
    },
  },
  methods: {
    startSurvey(item) {
      (this.activeSurvey = item), (this.showSurveyAnswerDialog = true);
    },
    categoryItems(category) {
      console.log(
        "!!! categoryItems: " + this.create.subcategories + category.id
      );
      return this.create.subcategories.filter(
        (sc) => sc.questioncategoryid === category.id
      );
    },
    categoryFullSelected(category) {
      return this.categoryItems(category).every((sc) =>
        category.selected.includes(sc.id)
      );
    },
    categoryHalfSelected(category) {
      var items = this.categoryItems(category);
      return (
        items.some((sc) => category.selected.includes(sc.id)) &&
        !items.every((sc) => category.selected.includes(sc.id))
      );
    },
    categorySelectChange(value, category) {
      if (value) {
        this.categoryItems(category)
          .filter((sc) => !category.selected.includes(sc.id))
          .forEach((sc) => category.selected.push(sc.id));
      } else {
        while (category.selected.length > 0) {
          category.selected.pop();
        }
      }
      //this._computedWatchers.selectedCategories.evaluate();
      this.$forceUpdate();
    },
    countSelectedQuestions(category) {
      var count = 0;
      this.create.subcategories
        .filter(
          (sc) =>
            sc.questioncategoryid == category.id &&
            category.selected.includes(sc.id)
        )
        .forEach((sc) => {
          count += sc.questioncount;
        });
      return count;
    },
    createSurvey(start) {
      this.create.loading = true;

      var postData = {};
      for (var f in this.create.fields) {
        if (
          !this.create.fields[f].disabled &&
          this.create.fields[f].value != null
        ) {
          postData[this.create.fields[f].var] = this.create.fields[f].value;
        }
      }
      postData["subcategories"] = [];
      this.create.categories.forEach((cat) => {
        postData["subcategories"] = postData["subcategories"].concat(
          cat.selected
        );
      });

      if (start) {
        postData["statusid"] = 2;
      }

      console.log(postData);

      this.call("surveys/CreateSurvey", postData).then((res) => {
        if (res.success) {
          console.log(res);
          this.create.admins.forEach((admin) => {
            this.call("groups/MakeUserAdminInGroup", {
              groupid: res.data.groupid,
              userid: admin.id,
            });
          });

          (this.create.show = false), (this.create.step = 1);
          this.create.loading = false;
          for (var f in this.create.fields) {
            this.create.fields[f].value = null;
            if (this.create.fields[f].type == "select") {
              this.create.fields[f].loadedData = null;
            }
          }
          this.create.categories = [];
          this.create.subcategories = [];
          this.reload = true;
        } else {
          this.error(res.msg);
        }
        this.create.loading = false;
      });
    },
    surveyDraft(item) {
      this.actionloading = true;
      console.log('surveyDraft!', this.actionloading);
      this.call("surveys/SurveyBacktoDraft", { surveyid: item.surveyid }).then(
        (res) => {
          if (res.success) {
            //reload table
            this.reload = true;
          }
          this.actionloading = false;
        }        
      );
    },
    surveyOpen(item) {
      this.actionloading = true;
      console.log('surveyOpen!');
      this.call("surveys/SurveyOpen", { surveyid: item.surveyid }).then(
        (res) => {
          if (res.success) {
            //reload table
            this.reload = true;
          }
          this.actionloading = false;
        }
      );
      
    },
    surveyCloseDialog(item) {
      console.log('surveyClose!');
      this.actionloading = true;
      this.call("surveys/GetSurveyParticipantsCount", {
        surveyid: item.surveyid,
      }).then((res) => {
        if (res.success) {
          this.confirmCloseId = item.surveyid;
          this.confirmCloseWarn = res.data.finished < 3;
          this.confirmClose = true;
        }
        this.actionloading = false;
      });
    },
    surveyClose() {
      this.confirmClose = false;
      this.call("surveys/SurveyClose", { surveyid: this.confirmCloseId }).then(
        (res) => {
          if (res.success) {
            //reload table
            this.reload = true;
          }
        }
      );
    },
  },
  watch: {
    showSurveyAnswerDialog() {
      if (this.showSurveyAnswerDialog == false) {
        this.reloadSurveys = true;
      }
    },
    "create.step"() {
      if (this.create.step == 2) {
        if (this.create.categories.length == 0) {
          this.call("questions/ListCategorys").then((res) => {
            if (res.success) {
              res.data.forEach((cat) => {
                cat.selected = [];
                this.create.categories.push(cat);
              });
            } else {
              this.error(res.msg);
            }
          });
        }
        if (this.create.subcategories.length == 0) {
          this.call("questions/ListSubcategorys").then((res) => {
            if (res.success) {
              this.create.subcategories = res.data;
            } else {
              this.error(res.msg);
            }
          });
        }
        /*this.create.loading = true;
                    this.call('groups/GetMyGroups').then(res => {
                        if(res.success){
                            this.create.teams = res.data;
                        }
                        else {
                            this.error(res.msg);
                        }
                        this.create.loading = false;
                    });*/
      }
    },
    "create.fields.startdate.value"() {
      console.log("startdate changed");
      var enddate = new Date(
        (parseInt(this.create.fields.startdate.value) + 1800) * 1000
      );
      console.log(enddate.getTime() / 1000);
      console.log(enddate.toISOString());

      this.create.fields.enddate.value = enddate.getTime() / 1000;

      //this.create.fields.enddate.min = null;
      this.create.fields.enddate.min = enddate.toISOString();
      console.log(this.create.fields.enddate);
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped></style>
