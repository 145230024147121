<template>
    <v-container>
        <v-row justify="center">
            <v-col md="4">
                <v-select v-if="groups != null" v-model="group" :items="groups" label="Team"></v-select>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="4">
                <v-select v-if="formats != null" v-model="format" :items="formats" label="Format"></v-select>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="4">
                <v-btn type="submit" color="primary" block class="ma-2 text-subtitle-2 text-capitalize" :loading="loading" :disabled="group == null || format == null" :href="group != null && format != null ? this.apihostname + `/api/v1/export/downloadCSV/${format}/${group.split(' ')[0]}`: '#'">Exportieren</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: function() {
            return {
                loading: false,
                groups: null,
                formats: null,
                group: null,
                format: null,
            }
        },
        methods: {
            async load() {
                var groupRes = await this.call('export/getGroups');
                if(groupRes.success){
                    this.groups = groupRes.data;
                }
                var formatRes = await this.call('export/getFormats');
                if(formatRes.success){
                    this.formats = formatRes.data;
                }
            }
        },
        watch: {
            company(){
                console.log("company changed");
                //this.loadGroups();
            }
        },
        mounted () {
            this.load();
        }
    }
</script>

<style scoped>

</style>