<template>
  <v-container fluid>
    <Box title="Einstellungen">
      <v-tabs>
        <v-tab to="/settings/user"
          ><v-icon left>contact_mail</v-icon> Persönliche Daten</v-tab
        >
        <v-tab to="/settings/password"
          ><v-icon left>vpn_key</v-icon> Passwort</v-tab
        >
        <!--
        <v-tab v-if="$store.userData.isSysAdmin == 1" to="/settings/teams"
          ><v-icon left>groups</v-icon> Teams</v-tab
        >
        -->
        <v-tab v-if="$store.userData.isSysAdmin == 1" to="/settings/survey"
          ><v-icon left>list_alt</v-icon> Umfragen</v-tab
        >
        <v-tab v-if="$store.userData.isSysAdmin == 1" to="/settings/export"
          ><v-icon left>system_update_alt</v-icon> Export</v-tab
        >
      </v-tabs>
      <v-divider />
      <router-view></router-view>
    </Box>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
#logo_settings {
  width: 100%;
  height: 250px;
  background-image: url(~@/assets/vlead-properties.jpg);
  background-size: cover;
}
</style>
