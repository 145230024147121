<template>
  <EditTable
    :reload="reload"
    :hasData.sync="hasData"
    title="Offene Team-Einladungen"
    subtitle="Folgende Einladungen können Sie noch annehmen."
    :headers="cols_invites"
    api_load="groups/ListMyInvites"
    nofilter
    hidewithnodata
    @reload="$emit('reload')"
    @update:reload="$emit('update:reload', $event)"
  >
    <template v-slot:default="item">
      <v-dialog v-model="confirmDecline" max-width="22%">
        <v-card>
          <v-card-title class="headline">
            Einladung ablehnen?
          </v-card-title>
          <v-card-text>
            <p>
              Möchten Sie wirklich die Einladung in das Team ablehnen?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="declineInvite(item)"
              >Ablehnen</v-btn
            >
            <v-btn color="green" text @click="confirmDecline = false"
              >Abbrechen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tooltip v-if="item.declined == 0" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            small
            fab
            @click="acceptInvite(item)"
            v-bind="attrs"
            v-on="on"
            ><v-icon>done</v-icon></v-btn
          >
        </template>
        <span>annehmen</span>
      </v-tooltip>
      <v-tooltip v-if="item.declined == 0" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="red"
            small
            fab
            @click="confirmDecline = true"
            v-bind="attrs"
            v-on="on"
            ><v-icon>block</v-icon></v-btn
          >
        </template>
        <span>ablehnen</span>
      </v-tooltip>
    </template>
  </EditTable>
</template>

<script>
export default {
  props: {
    reload: Boolean,
  },
  data: function() {
    return {
      hasData: false,
      confirmDecline: false,
      cols_invites: [
        { text: "Team", align: "start", value: (item) => item.groupdata.name },
        {
          text: "eingeladen von",
          value: (item) =>
            item.groupdata.creator.title +
            " " +
            item.groupdata.creator.firstname +
            " " +
            item.groupdata.creator.lastname,
        },
        { text: "Aktionen", align: "end", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    acceptInvite(item) {
      this.call("groups/AcceptInvite", { inviteid: item.id }).then(() => {
        this.$emit("action", true);
        this.$emit("update:reload", true);
      });
    },
    declineInvite(item) {
      this.call("groups/DeclineInvite", { inviteid: item.id }).then(() => {
        this.$emit("action", false);
        this.$emit("update:reload", true);
      });
    },
    declineConfirm() {},
  },
};
</script>
