// plugins/sasp.js
import apivars from "@/plugins/apivars";

export default {
  install: (app) => {
    window.$store = app.observable({
      initialized: false,
      error: {
        show: false,
        msg: null,
        source: null,
      },
      messages: [
        //{ text: 'Test Error', error: true },
        //{ text: 'Test Message', error: false },
      ],
      messageCount: 0,
      debug: true,
      loggedin: false,
      menu: false,
      userData: null,
      avatar: null,
      success: false,
      successMsg: null,
    });

    Object.defineProperty(app.prototype, "$store", {
      get() {
        return window.$store;
      },
      set(value) {
        window.$store = value;
      },
    });

    app.prototype.msg = (msg, title = "", error = false) => {
      var count = 1;
      window.$store.messages.forEach((m, i) => {
        if (m.text == msg) {
          count += m.count;
          app.prototype.delmsg(i);
        }
      });
      window.$store.messages.unshift({
        title: title,
        text: msg,
        error: error,
        count: count,
      });
      window.$store.messageCount++;
    };

    app.prototype.delmsg = (index) => {
      window.$store.messages.splice(index, 1);
    };

    //error functions
    app.prototype.error = (msg, source = "api") => {
      window.$store.error.msg = msg;
      window.$store.error.source = source;
      window.$store.error.show = apivars.api_errormessage;
      //app.prototype.msg(msg, true);
    };

    app.prototype.success = (msg) => {
      window.$store.successMsg = msg;
      window.$store.success = true;
    };

    //call api functions
    app.prototype.call = (_function, _postData = null) => {
      /*   app.prototype.fetch("users/isLoggedin").then((res) => {
        console.log("LoggedIn, ", res.data);
      });
 */
      app.prototype.status();

      return app.prototype.callApi(_function, _postData, window.$store.debug);
    };

    app.prototype.callApi = (
      _function,
      _postData = null,
      showError = false
    ) => {
      return new Promise((resolve) => {
        app.prototype
          .fetch(_function, _postData)
          .then((response) => {
            if (!response.success) {
              if (showError) {
                app.prototype.error(response.msg);
              }

              if (window.$store.loggedin) {
                var match = response.msg.match(/^(.+?)(?: ?\(#(\d+)\))?$/);
                app.prototype.msg(match[1], `Fehler #${match[2]}`, true);
              }
            }
            resolve(response);
          })
          .catch((error) => {
            app.prototype.error(`${error}`);
            resolve({ success: false, msg: `${error}` });
          });
      });
    };

    app.prototype.fetch = (_function, _postData = null) => {
      const _debug = apivars.apienvironment ? `?${apivars.apidebugging}` : "";
      const _url = `${apivars.apihostname}/api/v1/${_function}` + _debug;
      const _hasPostData = _postData != null;
      const _method = _hasPostData ? "POST" : "GET";
      const _body = _hasPostData ? JSON.stringify(_postData) : null;

      console.log("fetching URL: ", _url);
      console.log("fetching METHOD: ", _method);
      console.log("fetching BODY: ", _body);

      const _header = {
        "Content-Type": _hasPostData
          ? "application/json;charset=UTF-8"
          : "text/plain;charset=UTF-8",
      };

      return new Promise((resolve) => {
        fetch(_url, {
          method: _method,
          body: _body,
          headers: _header,
          credentials: "include",
          cache: "no-cache",
          mode: apivars.apicorsmode,
          redirect: "follow",
        }).then((response) => {
          const content = response.ok
            ? response.json()
            : { success: false, msg: `${response.statusText}` };
          resolve(content);
        }).catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
          console.error("fetching URL: ", _url);
          console.error("fetching METHOD: ", _method);
          console.error("fetching BODY: ", _body);
          console.error("fetching HEADER: ", _header);
        });
      });
    };

    app.prototype.loadAvatar = () => {
      var _avatarurl =
        apivars.apihostname +
        "/img/avatars/avatar.php?id=" +
        window.$store.userData.id +
        "&t=" +
        Date.now() +
        "&" +
        apivars.apidebugging;
      if (apivars.apienvironment == "docker") {
        _avatarurl =
          apivars.apihostname +
          "/img/avatars/avatar.php?id=" +
          window.$store.userData.id +
          "&t=" +
          Date.now();
      }
      window.$store.avatar = _avatarurl;
    };

    app.prototype.status = () => {
      return app.prototype.fetch("users/isLoggedin").then((res) => {
        window.$store.loggedin = res.success;
        if (window.$store.loggedin) {
          window.$store.userData = res.data[0];
          app.prototype.loadAvatar();
        }
        return res;
      });
    };

    // console.log("sasp: plugin loaded");
    // console.log("sasp: I am looking on " + apivars.apihostname);
    //call status from here?
    app.prototype.status().then(() => {
      // console.log("sasp status finished");
      //setTimeout(() => { window.$store.initialized = true; }, 5000);
      window.$store.initialized = true;
      // console.log("sasp plugin initialized");
    });
  },
};
