<template>
  <v-container>
    <EditTable title="Reflexionsfragen" :headers="header"></EditTable>
  </v-container>
</template>

<script>
export default {
  data: () => {
    return {
      header: [
        { text: "ID", value: "id" },
        { text: "Frage", value: "description" },
        { text: "Aktionen", align: "end", sortable: false, value: "actions" },
      ],
    };
  },
};
</script>
