<template>
  <v-container>
    <v-select
      v-if="companies != null"
      v-model="company"
      :items="companies"
      item-text="name"
      item-value="id"
      label="Unternehmen"
    ></v-select>
    <EditTable
      :reload.sync="reload"
      v-if="company != null"
      title="Teams"
      :headers="cols"
      :api_load="'groups/GetAllGroupsByCompanyId?id=' + this.company"
      :createfields="create"
      :api_create="'groups/CreateGroupforCompany?companyid=' + company"
      :editfields="edit"
      api_edit="groups/SetGroup"
    >
    </EditTable>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      reload: false,
      company: null,
      companies: null,
      cols: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Beschreibung", value: "description" },
        { text: "Versteckt", value: "ishidden", type: "checkbox" },
        { text: "Erstellt am", value: "createtime", type: "datetime" },
        { text: "Erstellt von", value: "createdby" },
        { text: "Aktionen", align: "end", sortable: false, value: "actions" },
      ],
      create: [
        { text: "Name", var: "name", value: null },
        { text: "Beschreibung", var: "description", value: null },
      ],
      edit: [
        { text: "ID", var: "id", value: null, readonly: true },
        { text: "Name", var: "name", value: null },
        { text: "Beschreibung", var: "description", value: null },
      ],
    };
  },
  methods: {
    load() {
      this.call("system/GetCompanies").then((res) => {
        console.log(res);
        if (res.success) {
          this.companies = res.data;
        } else {
          this.setError(res.msg);
        }
      });
    },
    archive(item) {
      this.call("groups/ArchiveGroup", { groupid: item.id }).then((res) => {
        console.log(res);
        if (res.success) {
          this.reload = true;
        } else {
          this.setError(res.msg);
        }
      });
    },
  },
  watch: {
    company() {
      console.log("company changed");
      //this.loadGroups();
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped></style>
