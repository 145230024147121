<template>
  <EditTable
    :reload="reload"
    :hasData.sync="hasData"
    title="Team-Mitgliedschaften"
    subtitle="In diesen Teams sind Sie Mitglied, haben jedoch keine Team-Leitung."
    :headers="cols_members"
    api_load="groups/GetMyGroupsOnlyMemberships"
    nofilter
    @reload="$emit('reload')"
    @update:reload="$emit('update:reload', $event)"
  >
    <template v-slot:default="item">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small fab @click="requestSurvey(item)" v-bind="attrs" v-on="on"
            ><v-icon>pending_actions</v-icon></v-btn
          >
        </template>
        <span>Umfrage anfordern</span>
      </v-tooltip>
    </template>
  </EditTable>
</template>

<script>
export default {
  props: {
      reload : Boolean,
  },
  data: function() {
    return {
      hasData: false,
      cols_members: [
        { text: "Team", align: "start", value: "name" },
        { text: "Beschreibung", value: "description" },
        { text: "erstellt am", value: "erstelltam", type: "datetime" },
        { text: "eingeladen von", value: "teamleader" },
        { text: "Aktionen", align: "end", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    requestSurvey(item) {
      this.call("surveys/RequestSurvey", { groupid: item.id }).then((res) => {
        if (res.success) {
          this.success(
            `Es wurde eine Umfrage für das Team '${item.name}' angefordert!`
          );
        } else {
          this.error(res.msg);
        }
      });
    },
    print(){
        console.log("reload");
    }
  },
  watch: {
    hasData() {
      this.$emit("input", this.hasData);
    },
  },
};
</script>
