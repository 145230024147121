import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon);

import Box from '@/components/Box.vue'
import HelpBox from '@/components/HelpBox.vue'
import EditForm from '@/components/EditForm.vue'
import EditTable from '@/components/EditTable.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'

Vue.component('Box', Box);
Vue.component('HelpBox', HelpBox);
Vue.component('EditForm', EditForm);
Vue.component('EditTable', EditTable);
Vue.component('DateTimePicker', DateTimePicker);

//dialogs
import SurveyAnswerDialog from '@/views/dialogs/SurveyAnswerDialog.vue'
Vue.component('SurveyAnswerDialog', SurveyAnswerDialog);

//tables
import InvitesTable from '@/views/tables/InvitesTable.vue'
Vue.component('InvitesTable', InvitesTable);
import OnlyMembersTable from '@/views/tables/OnlyMembersTable.vue'
Vue.component('OnlyMembersTable', OnlyMembersTable);

import pdfview from '@/plugins/pdfview';
import sasp from '@/plugins/sasp';
Vue.use(pdfview);
Vue.use(sasp);

import apivars from '@/plugins/apivars';
Vue.prototype.apienvironment = apivars.apienvironment;
Vue.prototype.apihostname = apivars.apihostname;
Vue.prototype.apidebugging = apivars.apidebugging;

//check login status
/*fetch("http://zimks2141clone.uni-trier.de:8080/api/users/isLoggedin")
  .then(response => {
    console.log(response);
    if (!response.ok) {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.json();
  })
  .then(res => {
    console.log("Check login:");
    console.log(res);
    window.$store.loggedin = res.success;

    new Vue({
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
})
.catch(error => {
  //this.errorMessage = error;
  console.error("There was an error!", error);
});*/

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENVIRONMENT !== 'development')  {
  console.log('Disabling console.log in production and staging');
  console.log = function() {};
} else {  
  console.log(`console.log enabled - Environment: ${process.env.VUE_APP_ENVIRONMENT}`);
}

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
