<template>
    <v-container>
        <EditTable :reload.sync="reload" title="Mitarbeiter" :headers="cols" api_load="company/GetCompanyUsers" :createfields="create" api_create="users/createuser" :editfields="edit" api_edit="users/SetUserData" deletefield="email" api_delete="users/DeleteUser" :deleteif="(item) => item.email != $store.userData.email" >
            <template v-slot:default="item">
                <v-tooltip bottom v-if="item.isCompanyAdmin == 0 && item.email != $store.userData.email">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  small fab @click="companyAdmin(item, 1)" v-bind="attrs" v-on="on"><v-icon>star</v-icon></v-btn> 
                    </template>
                    <span>Admin Berechtigung umschalten</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.isCompanyAdmin == 1 && item.email != $store.userData.email">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="accent" small fab @click="companyAdmin(item, 0)" v-bind="attrs" v-on="on"><v-icon>star_outline</v-icon></v-btn> 
                    </template>
                    <span>Admin Berechtigung umschalten</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.isLocked == 0 && item.email != $store.userData.email">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab @click="lock(item, 1)" v-bind="attrs" v-on="on"><v-icon>lock</v-icon></v-btn> 
                    </template>
                    <span>sperren</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.isLocked == 1 && item.email != $store.userData.email">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="accent" small fab @click="lock(item, 0)" v-bind="attrs" v-on="on"><v-icon>lock_open</v-icon></v-btn> 
                    </template>
                    <span>entsperren</span>
                </v-tooltip>
            </template>
        </EditTable>
    </v-container>
</template>

<script>
    export default {
        data: function() {
            return {
                reload: false,
                cols: [
                    { text: 'Vorname', value: 'firstname' },
                    { text: 'Nachname', value: 'lastname' },
                    { text: 'E-Mail', value: 'email' },
                    //{ text: 'Freigeschaltet', value: 'approved', type: 'checkbox' },
                    { text: 'Admin', value: 'isCompanyAdmin', type: 'checkbox' },
                    { text: 'Gesperrt', value: 'isLocked', type: 'checkbox', divider: true },
                    { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
                ],
                create: [
                    { text: 'E-Mail', var: 'email', value: null },
                    { text: 'Titel', var: 'title', value: null },
                    { text: 'Vorname', var: 'firstname', value: null },
                    { text: 'Nachname', var: 'lastname', value: null },
                    { text: 'Telefonnummer', var: 'phone', value: null },
                ],
                edit: [
                    { text: 'E-Mail', var: 'email', value: null, readonly: true },
                    { text: 'Titel', var: 'title', value: null },
                    { text: 'Vorname', var: 'firstname', value: null },
                    { text: 'Nachname', var: 'lastname', value: null },
                    { text: 'Telefonnummer', var: 'phonenumber', value: null },
                ],
            }
        },
        methods: {
            companyAdmin(item, value){
                this.call('users/MakeUserCompanyAdmin', { email: item.email, value: value }).then(res => {
                    if(res.success){
                        //reload table
                        this.reload = true;
                    }
                });
            },
            lock(item, value){
                this.call('users/LockUser', { email: item.email, value: value }).then(res => {
                    if(res.success){
                        //reload table
                        this.reload = true;
                    }
                });
            }
        },
    }
</script>

<style scoped>

</style>