<template>
  <v-container>
    <v-tabs>
      <v-tab v-if="$store.userData.isSysAdmin" to="/settings/survey/questions"
        ><v-icon left>help</v-icon> Fragen</v-tab
      >
      <v-tab
        v-if="$store.userData.isSysAdmin"
        to="/settings/survey/questioncategories"
        ><v-icon left>question_answer</v-icon> Fragenkategorien</v-tab
      >
      <v-tab
        v-if="$store.userData.isSysAdmin"
        to="/settings/survey/reflectivequestions"
        ><v-icon left>question_answer</v-icon> Reflexionsfragen</v-tab
      >
      <v-tab v-if="$store.userData.isSysAdmin" to="/settings/survey/variables"
        ><v-icon left>local_atm</v-icon> Variabeln</v-tab
      >
    </v-tabs>
    <v-divider />
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {};
  },
};
</script>

<style scoped></style>
