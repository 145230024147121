<template>
  <v-container fluid>
    <Box title="Ihre Teams">
      <p>
        Legen Sie hier Ihr (Projekt-)Team an.<br />
        Wenn Sie ein neues Team anlegen, werden Sie automatisch Teamleitung,<br />
        d.h. Sie können Mitglieder zu Ihrem Team einladen und für dieses
        Umfragen erstellen.
      </p>
      <p>
        Die Übersichten zeigen einmal alle von Ihnen angelegten Teams und ggfls.
        solche, in denen sie nur Mitlgied sind.
      </p>
      <HelpBox
        :items="[
          'Was kann ein „Team“ in Ihrem Fall sein und wofür können Sie den TeamCheck nutzen?',
        ]"
      >
        <template slot="0">
          <p>
            Projektteam: Der TeamCheck kann in diesem Fall zum Beispiel für die
            Bewertung von Meetings, Kick-Offs oder für Meilensteine genutzt
            werden. Auch, wenn sich die Projektsituation ändert, z. B. neue
            Aufgaben, andere Fristen, Hinzukommen neuer Teammitglieder, kann der
            TeamCheck hilfreich sein. Als Abschluss eines Projekts kann der
            TeamCheck helfen, die Zusammenarbeit zu evaluieren und Revue
            passieren zu lassen.
          </p>
          <p>
            Abteilungsteam: Der TeamCheck kann in diesem Fall beispielsweise für
            die Bewertung von Meetings (z. B. Jour fixe) verwendet werden. Auch,
            wenn sich das Abteilungsteam über einen gewissen Zeitraum aufgrund
            von Dienstreisen, Homeoffice o. ä. nicht persönlich sieht, ist der
            TeamCheck hilfreich, um eine gemeinsame Reflexion zum Stand des
            Teams anzuregen.
          </p>
          <p>
            Auch andere Arten von Teams, z.B. Qualitätszirkel, Managementteams
            und abteilungsübergreifende Teams, können als Teams in TeamCheck
            angelegt werden.
          </p>
        </template>
      </HelpBox>

      <InvitesTable
        :reload.sync="reloadInvitesTable"
        @action="
          (e) => {
            if (e) this.reload = true;
          }
        "
      />

      <OnlyMembersTable :reload.sync="reloadTeamTable" />

      <EditTable
        :reload.sync="reloadTeamleadTable"
        :headers="cols"
        title="Team-Leitungen"
        subtitle="Hier sehen Sie alle von Ihnen angelegten Teams."
        v-model="groups"
        api_load="groups/GetMyGroupsOnlyTeamleaderships"
      >
        <template v-slot:buttonbar>
          <v-btn
            color="primary"
            dark
            class="ma-2 text-subtitle-2 text-capitalize"
            @click="create.show = true"
            ><v-icon left>add</v-icon>Neues Team</v-btn
          >
        </template>
        <template v-slot:default="item">
          <!-- !!! <v-tooltip v-if="item.isAdmin" bottom> funktioniert nicht! -->
          <v-tooltip v-if="item.isAdmin == 1" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                small
                fab
                @click="showEdit(item)"
                v-bind="attrs"
                v-on="on"
                ><v-icon>edit</v-icon></v-btn
              >
            </template>
            <span>bearbeiten</span>
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                @click="requestSurvey(item)"
                v-bind="attrs"
                v-on="on"
                ><v-icon>pending_actions</v-icon></v-btn
              >
            </template>
            <span>Umfrage anfordern</span>
          </v-tooltip>
        </template>
      </EditTable>

      <v-dialog v-model="create.show" max-width="80%">
        <v-card tile>
          <v-card-title>
            <span>Neues Team erstellen</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="create.show = false">
              <v-icon>clear</v-icon>
            </v-btn>
          </v-card-title>

          <v-stepper v-model="create.step" non-linear>
            <v-stepper-header>
              <v-stepper-step step="1" editable :complete="create.teamDataValid"
                >Team</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step
                step="2"
                :editable="create.teamDataValid"
                :complete="create.members.length > 0 && create.membersValid"
                >Teammitglieder</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step
                step="3"
                :editable="create.members.length > 0 && create.membersValid"
                >Fertigstellen</v-stepper-step
              >
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form
                  v-model="create.teamDataValid"
                  v-on:submit.prevent="() => {}"
                >
                  <v-row dense v-for="(field, i) in create.fields" :key="i">
                    <v-col cols="4">
                      <v-subheader>{{ field.text }}</v-subheader>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="field.value"
                        :rules="field.rules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      text
                      @click="create.step = 2"
                      right
                      :disabled="!create.teamDataValid"
                      >Weiter <v-icon>keyboard_arrow_right</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-form v-model="create.membersValid">
                  <v-combobox
                    v-model="create.members"
                    label="Teammitglieder einladen"
                    placeholder="E-Mail Adressen von Teammitgliedern"
                    :loading="create.loading"
                    append-icon=""
                    multiple
                    chips
                    deletable-chips
                    disable-lookup
                    :rules="[inviteRules]"
                    required
                    :delimiters="[' ', ',', ';']"
                  />
                </v-form>
                <v-row>
                  <v-col>
                    <v-alert outlined border="left">
                      Als Teamleitung gehören Sie automatisch zum Team:
                      {{ $store.userData.email }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row v-show="create.members.length < 2">
                  <v-col>
                    <v-alert outlined type="warning" prominent border="left">
                      Eine Auswertung in TeamCheck ist erst ab einer
                      Mindestanzahl von drei Teammitgliedern möglich.<br />
                      Beachte: Als Teamleitung gehören Sie automatisch zum Team.
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto">
                    <v-btn text @click="create.step = 1"
                      ><v-icon>keyboard_arrow_left</v-icon> Zurück</v-btn
                    >
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      text
                      @click="create.step = 3"
                      :disabled="
                        create.members.length == 0 || !create.membersValid
                      "
                      >Weiter <v-icon>keyboard_arrow_right</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-row>
                  <v-col cols="4">
                    <Box title="Allgemeine Informationen">
                      <v-text-field
                        v-for="(field, i) in create.fields"
                        :key="i"
                        v-model="field.value"
                        :placeholder="field.text"
                        :rules="field.rules"
                        readonly
                        disabled
                      ></v-text-field>
                    </Box>
                  </v-col>
                  <v-col>
                    <Box title="Einzuladende Teammitglieder">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">E-Mail</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in create.members" :key="i">
                              <td>{{ item }}</td>
                              <!--td>{{ item.lastname }}</td>
                                                            <td>{{ item.firstname }}</td-->
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </Box>
                    <Box title="Teamleitung">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                E-Mail
                              </th>
                              <th class="text-left">
                                Name
                              </th>
                              <th class="text-left">
                                Vorname
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ $store.userData.email }}</td>
                              <td>{{ $store.userData.lastname }}</td>
                              <td>{{ $store.userData.firstname }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </Box>
                  </v-col>
                </v-row>
                <v-row v-show="create.members.length < 2">
                  <v-col>
                    <v-alert outlined type="warning" prominent border="left">
                      Eine Auswertung in TeamCheck ist erst ab einer
                      Mindestanzahl von drei Teammitgliedern möglich.<br />
                      Beachte: Als Teamleitung gehören Sie automatisch zum Team.
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto">
                    <v-btn text @click="create.step = 2"
                      ><v-icon>keyboard_arrow_left</v-icon> Zurück</v-btn
                    >
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      dark
                      class="ma-2 text-subtitle-2 text-capitalize"
                      @click="createGroup()"
                      :loading="create.loading"
                      ><v-icon left>save</v-icon> speichern & einladen</v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-dialog>

      <dialog-team-edit
        v-model="edit.show"
        :team="edit.item"
        @close="
          () => {
            reloadTeamleadTable = true;
          }
        "
      />
    </Box>
  </v-container>
</template>

<script>
import DialogTeamEdit from "./dialogs/DialogTeamEdit.vue";
export default {
  components: { DialogTeamEdit },
  name: "Teams",
  data: function() {
    return {
      reload: false,
      reloadInvitesTable: false,
      reloadTeamTable: false,
      reloadTeamleadTable: false,
      groups: null,
      create: {
        show: false,
        step: 1,
        fields: [
          {
            text: "Name",
            var: "name",
            value: null,
            rules: [(v) => !!v || "Name erforderlich"],
          },
          {
            text: "Beschreibung (optional)",
            var: "description",
            value: "",
          },
        ],
        isAdminCheckbox: false,
        teamDataValid: false,
        loading: false,
        //companyMembers: [],
        members: [],
        admins: [],
        membersValid: false,
      },
      edit: {
        show: false,
        step: 1,
        fields: [
          {
            text: "Name",
            var: "name",
            value: null,
            rules: [(v) => !!v || "Name erforderlich"],
          },
          {
            text: "Beschreibung (optional)",
            var: "description",
            value: "",
          },
        ],
        teamDataValid: false,
        loading: false,
        //companyMembers: [],
        actualMembers: [],
        members: [],
        actualAdmins: [],
        admins: [],
        actualInvitedMembers: [],
        inviteMembers: [],
        inviteMembersValid: false,
        item: null,
        columns: [
          { text: "Email", align: "start", value: "email" },
          { text: "Status", value: "status" },
          { text: "Teamleitung", value: "roll" },
          { text: "Aktionen", align: "end", sortable: false, value: "actions" },
        ],
      },
      cols: [
        { text: "Team", align: "start", value: "name" },
        { text: "Beschreibung", value: "description" },
        // { text: 'Teamleitung', value: "isAdminCheckbox", type: "checkbox"},
        { text: "erstellt am", value: "createtime", type: "datetime" },
        { text: "eingeladen", value: "countinvited" },
        // { text: "nur registriert", value: "countregistered" },
        { text: "angenommen", value: "countaccepted" },
        // { text: "abgelehnt", value: "countdeclined" },
        //{ text: 'Erstellt von', value: 'createdby' },
        { text: "Aktionen", align: "end", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    remove(array, item, key) {
      var index = array.findIndex((e) => {
        return e[key] == item[key];
      });
      if (index >= 0) array.splice(index, 1);
    },
    memberFilter(item, queryText) {
      return (
        item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.lastname
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.firstname
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    createGroup() {
      this.create.loading = true;
      var postData = {};
      this.create.fields.forEach((field) => {
        // Description Patch
        if (field.value == "") {
          field.value = "-";
        }
        if (!field.disabled && field.value != null) {
          postData[field.var] = field.value;
        }
      });
      postData.emails = this.create.members;
      this.call("groups/CreateGroup", postData).then((res) => {
        if (res.success) {
          console.log(res);
          /*this.create.admins.forEach(admin => {
                            this.call('groups/MakeUserAdminInGroup', { groupid: res.data.groupid, userid: admin.id });
                        });*/

          (this.create.show = false), (this.create.step = 1);
          this.create.members = [];
          //this.create.admins = [];
          this.create.fields.forEach((field) => {
            field.value = null;
          });
          this.create.teamDataValid = false;
          this.create.membersValid = false;
          this.reload = true;
        } else {
          this.error(res.msg);
        }
        this.create.loading = false;
      });
    },
    showEdit(item) {
      console.log("showEdit");
      this.edit.item = item;

      this.edit.fields.forEach((field) => {
        field.value = item[field.var];
      });
      //this.companyMembers = [];
      this.members = [];
      this.edit.step = 1;
      this.edit.show = true;
    },
    editGroup() {
      this.edit.loading = true;

      //console.log(this.edit.actualMembers);

      var add = [];
      var remove = [];
      //get add
      this.edit.members.forEach((member) => {
        if (this.edit.actualMembers.find((m) => m.id === member.id) == null) {
          add.push(member.id);
        }
      });

      //get remove
      this.edit.actualMembers.forEach((member) => {
        if (this.edit.members.find((m) => m.id === member.id) == null) {
          remove.push(member.id);
          if (member.isAdmin) {
            this.remove(this.edit.actualAdmins, member, "email");
          }
        }
      });
      //console.log(add);
      //console.log(remove);

      var setAdmin = [];
      var addAdmin = [];
      var removeAdmin = [];
      //get add aminds
      this.edit.admins.forEach((admin) => {
        if (this.edit.actualAdmins.find((m) => m.id === admin.id) == null) {
          if (add.find((a) => a === admin.id) == null) {
            setAdmin.push(admin.id);
          } else {
            addAdmin.push(admin.id);
          }
        }
      });

      //get remove admins
      this.edit.actualAdmins.forEach((admin) => {
        if (this.edit.admins.find((m) => m.id === admin.id) == null) {
          removeAdmin.push(admin.id);
        }
      });
      //console.log(setAdmin);
      //console.log(addAdmin);
      //console.log(removeAdmin);

      var addInvites = [];
      var removeInvites = [];
      //get added invites
      this.edit.inviteMembers.forEach((member) => {
        if (this.edit.actualInvitedMembers.find((m) => m === member) == null) {
          addInvites.push(member);
        }
      });

      //get removed invites
      this.edit.actualInvitedMembers.forEach((member) => {
        if (this.edit.inviteMembers.find((m) => m === member) == null) {
          removeInvites.push(member);
        }
      });
      //console.log(inviteMembers);
      //console.log(addInvites);
      //console.log(removeInvites);

      var postData = {};
      this.edit.fields.forEach((field) => {
        if (!field.disabled && field.value != null) {
          postData[field.var] = field.value;
        }
      });
      postData.groupid = this.edit.item.id;
      //console.log(postData);

      this.call("groups/SetGroup", postData).then((res) => {
        console.log(res);
        if (res.success) {
          add.forEach((id) => {
            this.call("groups/AddUserToGroup", {
              groupid: this.edit.item.id,
              userid: id,
            }).then((res) => {
              if (res.success) {
                if (addAdmin.find((a) => a === id) != null) {
                  this.call("groups/MakeUserAdminInGroup", {
                    groupid: this.edit.item.id,
                    userid: id,
                  });
                }
              }
            });
          });

          remove.forEach((id) => {
            this.call("groups/RemoveUserFromGroup", {
              groupid: this.edit.item.id,
              userid: id,
            });
          });

          setAdmin.forEach((id) => {
            this.call("groups/MakeUserAdminInGroup", {
              groupid: this.edit.item.id,
              userid: id,
            });
          });

          removeAdmin.forEach((id) => {
            this.call("groups/RemoveUserAdminInGroup", {
              groupid: this.edit.item.id,
              userid: id,
            });
          });

          if (addInvites.length > 0) {
            this.call("groups/InviteUsers", {
              groupid: this.edit.item.id,
              emails: addInvites,
            });
          }

          removeInvites.forEach((email) => {
            this.call("groups/RemoveUserInvite", {
              groupid: this.edit.item.id,
              email: email,
            });
          });

          this.edit.show = false;
          this.reload = true;
        } else {
          this.error(res.msg);
        }
        this.edit.loading = false;
      });
    },
    inviteRules(value) {
      if (value.length == 0) return "Geben Sie mindestens ein Mitglied an";
      for (var i in value) {
        console.log(`${value[i]} -> ${/.+@.+\..+/.test(value[i])}`);
        if (!/.+@.+\..+/.test(value[i])) {
          return "E-Mail nicht gültig";
        }
      }
      return true;
    },
    inviteRulesEdit(value) {
      for (var i in value) {
        //console.log(`${value[i]} -> ${/.+@.+\..+/.test(value[i])}`);
        if (!/.+@.+\..+/.test(value[i])) {
          return "E-Mail nicht gültig";
        } else if (this.edit.members.find((m) => m.email == value[i]) != null) {
          return "Ist bereits Mitglied";
        }
      }
      return true;
    },
    requestSurvey(item) {
      this.call("surveys/RequestSurvey", { groupid: item.id }).then((res) => {
        if (res.success) {
          this.success(
            `Es wurde eine Umfrage für das Team '${item.name}' angefordert!`
          );
        } else {
          this.error(res.msg);
        }
      });
    },
  },
  watch: {
    reload() {
      if (!this.reload) return;

      this.reloadInvitesTable = true;
      this.reloadTeamTable = true;
      this.reloadTeamleadTable = true;
      this.reload = false;
    },
    "create.step"() {
      this.$forceUpdate();
      /*if(this.create.step == 2 && this.create.companyMembers.length == 0)
                {
                    this.create.loading = true;
                    this.call('groups/ListAvailableUsersForGroup').then(res => {
                        console.log(res);
                        if(res.success){
                            this.create.companyMembers = res.data;

                            var me = this.create.companyMembers.find(member => member.id == this.$store.userData.id);
                            this.create.members.push(me);
                            this.create.admins.push(me);
                        }
                        else {
                            this.error(res.msg);
                        }
                        this.create.loading = false;
                    });
                }*/
    },
    "edit.step"() {
      if (this.edit.step == 2) {
        this.edit.loading = true;
        /*this.call('groups/ListAvailableUsersForGroup').then(res => {
                        console.log(res);
                        if(res.success){
                            this.edit.companyMembers = res.data;*/
        //get actual group members

        this.edit.inviteMembers = [];
        this.edit.actualInvitedMembers = [];
        this.edit.members = [];
        this.edit.actualMembers = [];
        this.edit.admins = [];
        this.edit.actualAdmins = [];
        this.call("groups/GetGroupInvites", {
          groupid: this.edit.item.id,
        }).then((res) => {
          console.log(res);
          if (res.success) {
            res.data.forEach((member) => {
              this.edit.inviteMembers.push(member.email);
              this.edit.actualInvitedMembers.push(member.email);
            });
          }

          this.call("groups/getGroupMembersWithoutTeamleaderByGroupId", {
            id: this.edit.item.id,
          }).then((res) => {
            console.log(res);
            if (res.success && res.data != null) {
              this.edit.members = res.data;
              this.edit.actualMembers = [];
              this.edit.members.forEach((member) => {
                this.edit.actualMembers.push(member);
                if (member.isAdmin != 0) {
                  this.edit.admins.push(member);
                  this.edit.actualAdmins.push(member);
                }
              });
            } else {
              this.edit.members = [];
              this.edit.actualMembers = [];
              this.edit.admin = [];
              this.edit.actualAdmins = [];
            }
            this.edit.loading = false;
          });
        });
        /*}
                        else {
                            this.error(res.msg);
                        }
                    });

                    */
      }
    },
  },
  mounted() {
    console.log("Mein baseServer in Teams.vue: " + this.apihostname);
  },
};
</script>

<style scoped></style>
