// plugins/sasp.js
export default {
    install: (app) => {
        window.$pdf = app.observable({
            view: false,
            title: null,
            file: null,
        });

        Object.defineProperty(app.prototype, '$pdf', {
            get () { return window.$pdf },
            set (value) { window.$pdf = value }
        })

        app.prototype.viewPdf = (title, file) => {
            window.$pdf.title = title;
            window.$pdf.file = file;
            window.$pdf.view = true;
        }
    }
  }