<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12" lg="8" xl="6">
                <v-card elevation="2" class="pa-4" outlined tile>
                    <v-card elevation="2" class="pa-4 mb-3 text-h6" color="primary lighten-1" dark>Passwort vergessen</v-card>
                    <v-container>
                        <v-form ref="registerform" v-model="valid" v-on:submit.prevent="reset">
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="email" v-model="email" :rules="emailRules" label="E-Mail Adresse *" required :disabled="attempt">
                                        <v-icon slot="append">mail_outline</v-icon>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            
                            <v-row v-show="error != null" justify="center" dense>
                                <v-col md="7">
                                    <v-alert dense type="error">
                                        Error: <strong>{{error}}</strong>
                                    </v-alert>
                                </v-col>
                            </v-row>
                            
                            <v-row justify="center">
                                <v-col md="4">
                                    <v-btn type="submit" color="primary" block class="ma-2 text-subtitle-2 text-capitalize" :disabled="!valid" :loading="attempt">E-Mail anfordern</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row justify="center" dense>
                            <v-col md="4" class="px-4">
                                <v-btn text @click="$router.push('/login')" block>Zurück zum Login</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: function() {
            return {
                emailRules: [
                    v => !!v || 'E-Mail erforderlich',
                    v => /.+@.+\..+/.test(v) || 'E-Mail nicht gültig',
                ],
                error: null,
                attempt: false,
                valid: false,
                email: null,
            }
        },
        methods: {
            reset: function() {
                this.attempt = true;
                this.call('users/RequestPasswordReset', {
                    "email": this.email,
                }).then(res => {
                    this.attempt = false;
                    if(res.success){
                        this.$router.push('/login');
                    }
                    else {
                        this.error = res.msg; //show error
                    }
                })
            }
        },
        created () {

        }
    }
</script>

<style scoped>

</style>