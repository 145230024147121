<template>
  <v-dialog
    v-if="survey != null && show && surveyCategories != null"
    v-model="show"
    max-width="80%"
  >
    <v-card tile>
      <v-card-title>
        <span>{{ survey.name }} - {{ survey.description }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-card-title>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step step="1" editable>So funktioniert's</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" editable :complete="answered"
            >Fragenbeantwortung ({{ surveyQuestions.length }})</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step step="3" :editable="answered"
            >Fertigstellen</v-stepper-step
          >
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <!--
                <v-row>
                  <Box title="So funktioniert's">
                -->
            <!--
                    <v-row>
                      <v-col>
                        <v-text-field :value="survey.name" label="Umfragename" readonly></v-text-field>
                        <v-text-field :value="survey.description" label="Beschreibung" readonly></v-text-field>
                        <v-text-field :value="survey.groupname" label="Teamname" readonly></v-text-field>
                        <v-text-field :value="survey.creator.email" label="Teamleiter" readonly></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field :value="surveyQuestions.length" label="Anzahl der Fragen" readonly></v-text-field>
                        <v-text-field :value="survey.detail" label="Detailgrad" readonly></v-text-field>
                        <v-text-field :value="new Date(survey.startdate * 1000).toLocaleString()" label="Umfragestart" readonly></v-text-field>
                        <v-text-field :value="new Date(survey.enddate * 1000).toLocaleString()" label="Umfrageende" readonly></v-text-field>
                      </v-col>
                    </v-row>
                    -->
            <v-row>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Spontan</v-list-item-title>
                    <v-list-item-subtitle
                      >Beantworten Sie die Fragen spontan und ehrlich, ohne
                      lange nachzudenken.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Momentaufnahme</v-list-item-title>
                    <v-list-item-subtitle
                      >Es gibt kein Richtig und kein Falsch.<br />Beantworten
                      Sie die Fragen so, wie Sie die Situation in Ihrem Team in
                      diesem Moment wahrnehmen.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Vertraulich</v-list-item-title>
                    <v-list-item-subtitle
                      >Ihre Teilnahme am TeamCheck ist anonym.<br />Eine
                      Auswertung erfolgt nur auf Teamebene und erst ab drei
                      Teilnehmenden.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Speichern</v-list-item-title>
                    <v-list-item-subtitle
                      >Ihr Fragebogen wird zwischengespeichert, so dass bei
                      einer Unterbrechung Ihre schon eingegebenen Antworten
                      nicht verloren gehen.<br />Denken Sie daran, den
                      Fragebogen auf der letzten Seite abzuschicken, so dass
                      Ihre Antworten in die Teamergebnisse einfließen können.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Ergebnisse</v-list-item-title>
                    <v-list-item-subtitle
                      >Ihre Ergebnisse können Sie sofort einsehen, sobald die
                      Umfrage beendet wird.<br />Dort finden Sie
                      Reflexionsfragen zur Besprechung der Ergebnisse im Team.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <!-- 
                  </Box>
                </v-row>
                -->
            <v-row>
              <v-spacer />
              <v-col cols="auto">
                <v-btn text @click="step = 2" right
                  >Weiter <v-icon>keyboard_arrow_right</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-stepper vertical>
              <template v-for="(category, i) in surveyCategories">
                <v-stepper-step
                  :key="category.id"
                  :step="i + 1"
                  :editable="i == 0 || surveyCategories[i - 1].answered"
                  :complete="category.answered"
                >
                  <span :class="category.answered ? 'green--text' : ''"
                    >{{ category.name }} ({{
                      category.questions.filter((q) => q.value != null).length
                    }}
                    / {{ category.questions.length }})</span
                  >
                </v-stepper-step>
                <v-stepper-content :key="'c_' + category.id" :step="i + 1">
                  <v-container>
                    <v-row
                      justify="center"
                      align="start"
                      dense
                      v-show="$vuetify.breakpoint.mdAndUp"
                    >
                      <v-col cols="6" class="text-subtitle-1">
                        Frage
                      </v-col>
                      <v-col
                        cols="1"
                        class="text-subtitle-2"
                        v-for="n in 6"
                        :key="n"
                      >
                      <p align="center">
                        {{ category.questions[0].answertyp["value" + n] }}
                      </p>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-row
                      v-for="item in category.questions"
                      :key="item.qid"
                      dense
                    >
                      <v-col>
                        <v-radio-group
                          v-model="item.value"
                          @change="answerChange(category, item)"
                          class="ma-0"
                          row
                        >
                          <v-container class="pa-0">
                            <v-row justify="center" align="center" dense>
                              <v-col cols="12" md="6" class="font-weight-normal">
                                {{ item.question }}
                              </v-col>
                              <!-- reverse nur value drehen -->
                              <template
                                v-if="
                                  item.answertyp.name == 'Standard' ||
                                    item.answertyp.name == 'Likert 1' ||
                                    item.answertyp.name == 'Likert 2' ||
                                    item.answertyp.name == 'Likert 3'
                                "
                              >
                                <v-col
                                  cols="auto"
                                  md="1"
                                  v-for="n in 6"
                                  :key="n"
                                  class="mt-2 d-flex justify-center"
                                >
                                  <!--v-radio :label="$vuetify.breakpoint.smAndDown ? item.answertyp['value'+n] : ''" :value="n"></v-radio-->
                                  <v-radio
                                    :label="
                                      $vuetify.breakpoint.smAndDown
                                        ? item.answertyp['value' + n] 
                                        : ''
                                    "
                                    :value="n"
                                  ></v-radio>
                                </v-col>
                              </template>
                              <template
                                v-if="item.answertyp.name == 'Standard Reverse'"
                              >
                                <v-col
                                  cols="auto"
                                  md="1"
                                  v-for="n in 6"
                                  :key="n"
                                  class="mt-2 d-flex justify-center"
                                >
                                  <!--v-radio :label="$vuetify.breakpoint.smAndDown ? item.answertyp['value'+n] : ''" :value="n"></v-radio-->
                                  <v-radio
                                    :label="
                                      $vuetify.breakpoint.smAndDown
                                        ? item.answertyp['value' + (7 - n)]
                                        : ''
                                    "
                                    :value="7 - n"
                                  ></v-radio>
                                </v-col>
                              </template>
                              <template
                                v-else-if="
                                  item.answertyp.name == 'Smiley Kunin'
                                "
                              >
                                <v-col
                                  cols="auto"
                                  md="1"
                                  v-for="n in 6"
                                  :key="n"
                                  class="pt-4"
                                >
                                  <v-radio
                                    class="emote"
                                    :value="n"
                                    :on-icon="
                                      'mdi-' + item.answertyp['value' + n]
                                    "
                                    :off-icon="
                                      'mdi-' +
                                        item.answertyp['value' + n] +
                                        '-outline'
                                    "
                                  ></v-radio>
                                </v-col>
                              </template>
                              <template
                                v-else-if="item.answertyp.value1 == 'prozent'"
                              >
                                <v-col class="pt-9">
                                  <v-slider
                                    v-model="item.value"
                                    @change="answerChange(category, item)"
                                    min="0"
                                    max="100"
                                    thumb-label="always"
                                  >
                                    <template v-slot:prepend>
                                      <span class="body-1">{{
                                        item.answertyp.value2
                                      }}</span>
                                    </template>
                                    <template v-slot:append>
                                      <span class="body-1">{{
                                        item.answertyp.value3
                                      }}</span>
                                    </template>
                                    <template v-slot:thumb-label="{ value }">
                                      {{ value }}%
                                    </template>
                                  </v-slider>
                                </v-col>
                              </template>
                            </v-row>
                          </v-container>
                        </v-radio-group>
                        <v-divider />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>
              </template>
            </v-stepper>

            <v-row>
              <v-col cols="auto">
                <v-btn text @click="step = 1"
                  ><v-icon>keyboard_arrow_left</v-icon> Zurück</v-btn
                >
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-btn text @click="step = 3" :disabled="!answered"
                  >Weiter <v-icon>keyboard_arrow_right</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-row>
              <v-col>
                <span
                  >Nachdem Sie auf <b>'Abschicken'</b> geklickt haben, werden
                  Ihre Antworten dauerhaft gespeichert und können nicht mehr
                  geändert werden.</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn text @click="step = 2"
                  ><v-icon>keyboard_arrow_left</v-icon> Zurück</v-btn
                >
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  dark
                  class="ma-2 text-subtitle-2 text-capitalize"
                  @click="endSurvey()"
                  :loading="loading"
                  ><v-icon left>done</v-icon> Abschicken</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    survey: {
      type: Object,
    },
  },
  data: function() {
    return {
      surveyQuestions: null,
      surveyCategories: null,
      step: 1,
      loading: false,
    };
  },
  methods: {
    async startSurvey() {
      console.log(this.survey);

      this.step = 1;
      this.surveyQuestions = [];
      this.surveyCategories = null;
      var givenAnswers = [];

      if (this.survey.IsStartable4User) {
        console.log("Survey not startet... statring...");
        var startRes = await this.call("surveys/StartSurvey", {
          surveyid: this.survey.surveyid,
        });
        if (!startRes.success) {
          return;
        }
      } else {
        console.log("Survey arleardy startet...");
        var answerRes = await this.call("surveys/ListUserAnswers/", {
          surveyid: this.survey.surveyid,
        });
        givenAnswers = answerRes.data;
      }

      this.call("surveys/ListSurveyQuestions/", {
        surveyid: this.survey.surveyid,
      }).then((res) => {
        this.surveyQuestions = res.data;

        this.surveyCategories = [];
        this.surveyQuestions.forEach((q) => {
          q.value =
            givenAnswers[q.dbid] != null
              ? parseInt(givenAnswers[q.dbid].value)
              : null;
          var ci = this.surveyCategories.findIndex((cat) => cat.id == q.catid);
          if (ci == -1)
            this.surveyCategories.push({
              id: q.catid,
              name: q.catname,
              answered: false,
              questions: [q],
            });
          else this.surveyCategories[ci].questions.push(q);
        });

        this.surveyCategories.forEach((category) => {
          category.answered =
            category.questions.filter((q) => q.value == null).length == 0;
        });

        console.log(this.surveyCategories[0]);
        console.log("Open survey dialog");
        this.$forceUpdate();
      });
    },
    answerGivenCount(category) {
      return category.questions.filter((q) => q.value != null).length;
    },
    answerChange(category, item) {
      console.log("answerChange");
      console.log(item);

      //call SetUserAnswer
      this.call("surveys/SetUserAnswer", {
        surveyid: this.survey.surveyid,
        dbid: item.dbid,
        value: item.value,
      }).then((res) => {
        console.log(res);
      });

      category.answered =
        category.questions.filter((q) => q.value == null).length == 0;
      this.$forceUpdate();
    },
    endSurvey() {
      this.loading = true;
      this.call("surveys/EndSurvey", { surveyid: this.survey.surveyid }).then(
        (res) => {
          if (res.success) {
            this.show = false;
          }
        }
      );
    },
  },
  computed: {
    answered() {
      return this.surveyCategories != null
        ? this.surveyCategories.filter((c) => !c.answered).length == 0
        : false;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value() {
      if (this.value && this.survey != null) {
        this.startSurvey();
      }
    },
  },
};
</script>
