<template>
    <v-container fluid>
        <Box title="Ergebnisse">
            <p>
                Hier sehen Sie Ihre Ergebnisse aus den Umfragen des TeamChecks.
            </p>
            <p>
                Sobald das Enddatum erreicht ist oder alle Teammitglieder die Umfrage ausgefüllt haben, werden die Ergebnisse angezeigt. Alle Teammitglieder können diese Ergebnisse nach Beendigung der Umfrage einsehen.
            </p>

            <HelpBox :items="[ 'Die Ergebnisse stehen Ihnen in zwei Formaten zur Verfügung:', 'Empfehlung zum Umgang mit den Ergebnissen:', 'Reflexionsfragen' ]">
                <template slot=0>
                    <p>
                        1. Auf einen Blick sehen Sie hier in grafischer Form und entsprechend des Detailgrades Ihres Unternehmens die Ergebnisse Ihrer Umfrage für die jeweiligen Fragemodule sowie für die prozentuale Verteilung der Antworten auf die einzelnen Fragen, wenn Sie auf das jeweilige Fragenmodul klicken.
                    </p>
                    <p>
                        2. Zusätzlich steht Ihnen ein Ergebnisreport in Form einer pdf-Datei zum Herunterladen zur Verfügung. Dieser Report enthält Reflexionsfragen und Handlungsempfehlungen.
                    </p>
                    <!--p>
                        3. Verlaufsdaten erhalten Sie ab der zweiten Nutzung des TeamChecks innerhalb eines Teams. Voraussetzung ist, dass in den Umfragen dieselben Fragemodule abgefragt wurden.
                    </p-->
                </template>
                <template slot=1>
                    <p>
                        Sollten Sie die Ergebnisse einer Umfrage direkt nach der Beantwortung im Meeting besprechen wollen, sollten Sie genügend Zeit für die Ergebnisdiskussion einplanen. Es ist möglich, dass durch die Ergebnisrückmeldung im Nachgang auch heikle Themen angesprochen werden, die mehr Zeit für die gemeinsame Reflexion in Anspruch nehmen.
                    </p>
                    <p>
                        Alternativ können Sie auch die Besprechung der Ergebnisse im nächsten Meeting als Punkt in die Agenda aufnehmen. Dadurch haben alle Teammitglieder die Chance, sich mit den Ergebnissen und den Reflexionsfragen vorab individuell auseinander zu setzen und bereits konstruktive Lösungsvorschläge zu erarbeiten. 
                    </p>
                </template>
                <template slot=2>
                    <p>
                        Mit den folgenden Fragen können Sie eine Diskussion im Team zu Ihren Ergebnissen anregen:
                    </p>
                    <ul class="ml-5 mb-4">
                        <li>Mit welchen Aspekten waren die Teammitglieder zufrieden?</li>
                        <li>Mit welchen Aspekten waren die Teammitglieder unzufrieden?</li>
                        <li>Wie begründen die Teammitglieder Ihre Einschätzung?</li>
                        <li>Aus welchen Gründen gehen die Meinungen im Team auseinander?</li>
                        <li>Was könnten Sie im Team tun, um den jeweiligen Aspekt zu verbessern?</li>
                    </ul>
                    <p>
                        Weitere, spezifisch auf die einzelnen Fragemodule zugeschnittene Reflexionsfragen finden Sie unter den Ergebnissen der jeweiligen Fragemodule.
                    </p>
                </template>
            </HelpBox>
            <EditTable :loadeddata.sync="groups" :reload.sync="reload" title="Umfragen" :headers="cols" api_load="surveys/ListMySurveys/1/0/3" >
                <template v-slot:default="item">
                    <v-btn v-if="item.resultsavailable" small :href="resURL(item.random)" target="_blank" class="mr-2" color="success"><v-icon left>get_app</v-icon> Ergebnisse herunterladen</v-btn> 
                    <v-tooltip v-if="item.resultsavailable" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small fab @click="jumpToProgress(item.groupid)" v-bind="attrs" v-on="on" class="mr-2"><v-icon>show_chart</v-icon></v-btn> 
                        </template>
                        <span>Teamverlauf anzeigen</span>
                    </v-tooltip>
                    <v-tooltip v-if="item.resultsavailable" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small fab :to="{name: 'results_item', params: { randomId: item.random }}" v-bind="attrs" v-on="on" color="success"><v-icon>launch</v-icon></v-btn> 
                        </template>
                        <span>Ergebnisse anzeigen</span>
                    </v-tooltip>
                    <v-tooltip v-else bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small fab v-bind="attrs" v-on="on" color="warning"><v-icon>help_outline</v-icon></v-btn> 
                        </template>
                        <span class="text-h6">Keine Ergebnisse</span>
                        <p class="font-weight-bold">
                            Zu dieser Umfrage können keine Ergebnisse abgerufen werden,<br>
                            da weniger als 3 Benutzer teilgenommen haben.
                        </p>
                    </v-tooltip>
                </template>
            </EditTable>
        </Box>
        <Box  title="Verlauf der letzten Umfragen" v-if="groups != null">
            <p>
                Die folgende Übersicht zeigt Ihnen die Daten über die letzten 10 Umfragen hinweg im Verlauf an (geordnet nach Enddatum der Umfragen). Hohe Werte sind wünschenswert. Sie können zur Betrachtung einzelner Teamprozesse diejenigen Faktoren durch Klick auf die Legende abwählen, die Sie nicht in der Übersicht dargestellt sehen möchten. 
            </p>
            <v-select v-model="group" :items="groups" item-text="groupname" item-value="groupid" label="Team"></v-select>
            <object v-if="group != null" :data="progURL" type="image/svg+xml" />
            <div id="progress" />
        </Box>
    </v-container>
</template>

<script>
    export default {
        name: 'Results',
        data: () => {
            return {
                reload: false,
                cols: [
                    //{ text: 'ID', value: 'id' },
                    { text: 'Umfrage', value: 'name' },
                    { text: 'Team', value: 'groupname' },
                    { text: 'letzte Reaktion', value: 'lastupdate', type: 'datetime' },
                    { text: "n/max.", value: "userParticipationCount" },
                    { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
                ],
                groups: null,
                group: null,
                progURL: null  // progress results
            }
        },   
        methods: {
            resURL(randomid) {
                if (this.apienvironment === "local") {
                    return this.apihostname+'/api/v1/results/getPDFResultRnd/'+randomid+'?'+this.apidebugging;
                } else {
                    return this.apihostname+'/api/v1/results/getPDFResultRnd/'+randomid;
                }
            },
            jumpToProgress(groupid){
                this.group = groupid;
                if (this.apienvironment === "local") {
                    this.progURL = this.apihostname+'/api/v1/results/getCourseData/'+this.group+'?'+this.apidebugging;
                } else {
                    this.progURL = this.apihostname+'/api/v1/results/getCourseData/'+this.group;
                }
                window.location.hash = '';
                window.location.hash = 'progress';
            }
        }
    }
</script>

<style scoped>

</style>