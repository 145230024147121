<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="12" lg="8" xl="6">
        <v-card elevation="2" class="pa-4" outlined tile>
          <v-card
            elevation="2"
            class="pa-4 mb-3 text-h6"
            color="primary lighten-1"
            dark
            >Login</v-card
          >
          <v-container>
            <v-form
              ref="loginform"
              v-model="loginformValid"
              v-on:submit.prevent="login"
            >
              <v-row justify="center" dense>
                <v-col md="7">
                  <v-text-field
                    id="email"
                    v-model="email"
                    :rules="emailRules"
                    label="E-Mail Adresse *"
                    required
                    :disabled="attemptLogin"
                    append-icon="mail_outline"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col md="7">
                  <!-- <v-text-field
                    id="password"
                    type="password"
                    v-model="password"
                    :rules="passwordRules"
                    label="Passwort *"
                    required
                    :disabled="attemptLogin"
                    append-icon="lock"
                  >
                  </v-text-field> -->
                  <app-password-field v-model="password"></app-password-field>
                </v-col>
              </v-row>

              <v-row v-show="error != null" justify="center" dense>
                <v-col md="7">
                  <v-alert dense type="error">
                    Error: <strong>{{ error }}</strong>
                  </v-alert>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col md="4">
                  <v-btn
                    type="submit"
                    color="primary"
                    block
                    class="text-subtitle-2 text-capitalize"
                    :disabled="!loginformValid"
                    :loading="attemptLogin"
                    >Login</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-row justify="center" dense>
              <v-col md="4" class="px-4">
                <v-btn text @click="$router.push('/register')" block
                  >Registrieren</v-btn
                >
              </v-col>
            </v-row>
            <v-row justify="center" dense>
              <v-col md="4" class="mt-4 text-center">
                <a @click="$router.push('/forgotpassword')"
                  >Passwort vergessen?</a
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppPasswordField from "../components/AppPasswordField.vue";
export default {
  components: { AppPasswordField },
  data: function() {
    return {
      attemptLogin: false,
      email: null,
      password: null,
      loginformValid: false,
      emailRules: [
        (v) => !!v || "E-Mail erforderlich",
        (v) => /.+@.+\..+/.test(v) || "E-Mail nicht gültig",
      ],
      passwordRules: [(v) => !!v || "Passwort erforderlich"],
      error: null,
    };
  },
  methods: {
    login: function() {
      this.attemptLogin = true;
      console.log("login trial");
      this.call("users/login", {
        email: this.email,
        password: this.password,
      }).then((res) => {
        this.attemptLogin = false;
        console.log("attempted login");
        console.log(res);

        if (res.success) {
          //this.$loggedin = true;
          this.status().then((res) => {
            if (res.success) {
              this.redirect();
            }
          });
        } else {
          this.error = res.msg; //show error
        }
      });
    },
    redirect: function() {
      if (this.$route.query.return) {
        this.$router.push(this.$route.query.return);
      } else this.$router.push("/home");
    },
  },
  created() {},
};
</script>

<style scoped></style>
