<template>
    <v-container fluid>
        <EditForm v-model="$store.userData" :fields="fields" title="Benutzerdaten" api_write="users/SetMyUserData" />
        <v-row>
            <v-col cols="5">
                <span class="text-h6">Avatar</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-avatar size="80">
                    <v-img cover :src="$store.avatar" height="80px" width="80px" />
                </v-avatar>
            </v-col>
        </v-row>
        <v-row v-if="avatarEdit">
            <v-col>
                <v-slide-group v-model="avatar" :show-arrows="true" mandatory>
                    <v-slide-item v-for="i in 19" :key="i" v-slot="{ active, toggle }" >
                        <v-btn fab elevation="0" @click="toggle" height="100%" width="80px" :disabled="active" :style="active ? 'filter: none;' : 'filter: grayscale(0.8);'" >
                            <v-img cover :src="avURL(i)" height="80px" width="80px" />
                        </v-btn>
                    </v-slide-item>
                </v-slide-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn v-show="!avatarEdit" color="primary" dark class="ma-2 text-subtitle-2 text-capitalize" @click="avatarEdit = true"><v-icon left>edit</v-icon> Bearbeiten</v-btn>
                <v-btn v-show="avatarEdit" color="primary" dark class="ma-2 text-subtitle-2 text-capitalize" @click="saveAvatar()" :loading="loading" :disabled="avatar == null || loading"><v-icon left>save</v-icon> Speichern</v-btn>
                <v-btn v-show="avatarEdit" color="error" dark class="ma-2 text-subtitle-2 text-capitalize" @click="avatarEdit = false;avatar=null;" :disabled="loading"><v-icon left>clear</v-icon> Verwerfen</v-btn>
            </v-col>
        </v-row>
        
        <v-dialog v-model="confirmDelete" max-width="22%">
            <v-card>
                <v-card-title class="headline">
                    Ihren Account löschen?
                </v-card-title>
                <v-card-text>
                    <p>
                        Sie sind im Begriff Ihren Account sowie alle Teammitgliedschaften <b>unwiderruflich</b> zu löschen. Alle Personenbezogenen Daten werden entfernt.
                    </p>
                    <v-checkbox v-model="confirmDeleteCheck" label="Hiermit bestätige ich, dass mein Account endgültig gelöscht werden soll"></v-checkbox>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="deleteAccount()" :disabled="!confirmDeleteCheck">Account löschen</v-btn>
                    <v-btn color="green" text @click="confirmDelete = false">Abbrechen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--
        <EditTable title="Zugewiesene Teams" :headers="cols" api_load="groups/getmygroups" nofilter />
        -->
        <v-row>
            <v-col cols="5">
                <span class="text-h6">Account</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn color="error" dark class="ma-2 text-subtitle-2 text-none" @click="confirmDelete = true">Account löschen</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: function (){
            return {
                fields: [
                    { text: 'E-Mail', var: 'email', value: null, disabled: true },
                    { text: 'Titel', var: 'title', value: null },
                    { text: 'Vorname', var: 'firstname', value: null },
                    { text: 'Nachname', var: 'lastname', value: null },
                    //{ text: 'Telefonnummer', var: 'phonenumber', value: null },
                ],
                cols: [
                    { text: 'Name', align: 'start', value: 'name' },
                    { text: 'Beschreibung', value: 'description' },
                    { text: 'Admin', value: 'isAdmin', type: 'checkbox' },
                ],
                avatarEdit: false,
                avatar: null,
                loading: false,
                confirmDelete: false,
                confirmDeleteCheck: false,
            }
        },
        methods: {
            avURL(avid){
                return this.apihostname+'/img/avatars/pic'+avid+'.png';
            },
            saveAvatar(){
                this.loading = true;
                this.call('users/SetDefaultAvatar', {avatarnr: this.avatar+1}).then(res => {
                    if(res.success){
                        this.loading = false;
                        this.avatarEdit = false;
                        this.loadAvatar();
                    }
                    else {
                        //todo show write error
                        this.error(res.msg);
                    }
                });
            },
            deleteAccount(){
                this.call('users/DeleteUser').then(res => {
                    if(res.success){
                        this.$store.loggedin = false;
                        this.$router.push('/login');
                    }
                    else {
                        //todo show write error
                        this.error(res.msg);
                    }
                });
            }
        },
        mounted() {
            
        },
        created() {
            
        }
    }
</script>

<style scoped>

</style>