import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import NewPassword from "@/views/NewPassword.vue";
import Home from "@/views/Home.vue";
import Teams from "@/views/Teams.vue";
import Surveys from "@/views/Surveys.vue";
import Results from "@/views/Results.vue";
import ResultsItem from "@/views/ResultsItem.vue";
import Settings from "@/views/Settings.vue";
import SettingsUser from "@/views/settings/User.vue";
import SettingsPassword from "@/views/settings/Password.vue";
import SettingsTool from "@/views/settings/Tool.vue";
import SettingsUsers from "@/views/settings/Users.vue";
import SettingsTeams from "@/views/settings/Teams.vue";
import SettingsExport from "@/views/settings/Export.vue";
import SettingsSurvey from "@/views/settings/Survey.vue";
import SurveyQuestions from "@/views/settings/survey/Questions.vue";
import SurveyQuestionCategories from "@/views/settings/survey/QuestionCategories.vue";
import SurveyReflectiveQuestions from "@/views/settings/survey/ReflectiveQuestions.vue";
import SurveyVariables from "@/views/settings/survey/Variables.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/newpassword/:token",
    name: "NewPassword",
    props: true,
    component: NewPassword,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/teams",
    name: "Teams",
    component: Teams,
  },
  {
    path: "/surveys",
    name: "Surveys",
    component: Surveys,
  },
  {
    path: "/results",
    name: "Results",
    component: Results,
  },
  {
    path: "/results/:randomId",
    name: "results_item",
    props: true,
    component: ResultsItem,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    redirect: "/settings/user",
    children: [
      {
        path: "user",
        name: "Settings-User",
        component: SettingsUser,
      },
      {
        path: "password",
        name: "Settings-Password",
        component: SettingsPassword,
      },
      {
        path: "tool",
        name: "Settings-Tool",
        component: SettingsTool,
      },
      {
        path: "users",
        name: "Settings-Users",
        component: SettingsUsers,
      },
      {
        path: "teams",
        name: "Settings-Teams",
        component: SettingsTeams,
      },
      {
        path: "export",
        name: "Settings-Export",
        component: SettingsExport,
      },
      {
        path: "survey",
        name: "Settings-Survey",
        component: SettingsSurvey,
        redirect: "/settings/survey/questions",
        children: [
          {
            path: "questions",
            name: "Settings-Survey-Questions",
            component: SurveyQuestions,
          },
          {
            path: "questioncategories",
            name: "Settings-Survey-QuestionCategories",
            component: SurveyQuestionCategories,
          },
          {
            path: "reflectivequestions",
            name: "Settings-Survey-ReflectiveQuestions",
            component: SurveyReflectiveQuestions,
          },
          {
            path: "variables",
            name: "Settings-Survey-Variables",
            component: SurveyVariables,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // base: "http://localhost/client",
  routes,
});

router.beforeEach((to, from, next) => {
  /*while(!window.$store.initialized){
    console.log('not initialized...');
  }*/
  console.log("before route");
  console.log(to);
  if (!window.$store.initialized) {
    next();
  } else if (
    !window.$store.loggedin &&
    to.name != "Login" &&
    to.name != "Register" &&
    to.name != "ForgotPassword" &&
    to.name != "NewPassword"
  ) {
    //redirect to login
    //const loginpath = window.location.pathname;
    window.$store.menu = false;
    next({ name: "Login", query: { return: to.path } });
  } else if (
    window.$store.loggedin &&
    (to.name == "Login" ||
      to.name == "Register" ||
      to.name == "ForgotPassword" ||
      to.name == "NewPassword")
  ) {
    //redirect to home
    next({ name: "Home" });
    window.$store.menu = true;
  } else next();
});

export default router;
