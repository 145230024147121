<template>
  <v-container fluid>
    <v-row :align="verticalAlign" :justify="horizontalAlign">
      <v-col :cols="titleCols">
        <v-subheader v-if="!hasSlot('title')" class="text-subtitle-1">{{
          title
        }}</v-subheader>
        <slot v-else name="title"></slot>
      </v-col>
      <v-col :cols="fieldCols">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "app-form-field",
  props: {
    title: String,
    horizontalAlign: {
      type: String,
      default: "start",
    },
    verticalAlign: {
      type: String,
      default: "start",
    },
    titleCols: {
      type: String,
      default: "2",
    },
    fieldCols: {
      type: String,
      default: "0",
    },
  },
  methods: {
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
};
</script>
