console.log(`Settings Build Version: ${process.env.VUE_APP_VERSION}`);
console.log(`Settings Environment: ${process.env.VUE_APP_ENVIRONMENT}`);

if (process.env.VUE_APP_ENVIRONMENT == "development") {
    // dev local
    var apienvironment = "local";
    var apihostname = process.env.VUE_APP_API_URL;
    var apidebugging = "XDEBUG_SESSION_START=ECLIPSE";
    var api_errormessage = true;
    var apicorsmode = "cors";
    console.log('I am looking for api on: ', apihostname);
} else {
    // production or staging docker
    apienvironment = "docker";
    apihostname = process.env.VUE_APP_API_URL;
    apidebugging = "";
    apicorsmode = "cors";
    api_errormessage = false;
    console.log('I am looking for api on: ' + location.hostname + '/api/v1/');
}

export default {
    apienvironment,
    apihostname,
    apidebugging,
    apicorsmode,
    api_errormessage
  };


