<template>
  <v-text-field
    v-model="password"
    :label="label"
    counter
    :type="show ? 'text' : 'password'"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    :rules="rules"
    @click:append="show = !show"
  />
</template>

<script>
export default {
  props: {
    value: String,
  },
  data: () => {
    return {
      show: false,
      rules: [
        (value) => !!value || "Passwort erforderlich.",
      ],
      label: "Passwort*",
    };
  },
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
