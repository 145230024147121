<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :loading="loading"
    loading-text="Laded... Bitte warten!"
  >
    <template v-slot:top>
      <v-toolbar flat class="grey lighten-5">
        <v-toolbar-title v-if="showTitle">{{ title }}</v-toolbar-title>
        <v-divider v-if="showTitle" class="mx-4" inset vertical />
        <template v-if="hasSlot('toolbar')">
          <v-row no-gutter>
            <v-col v-if="hasSlot('toolbar')"
              ><slot name="toolbar"></slot
            ></v-col>
          </v-row>
        </template>
        <template v-else>
          <v-text-field
            v-model="search"
            label="Suche"
            hide-details
            single-line
            clearable
            append-icon="search"
          />
          <template v-if="showButtonBar">
            <v-divider class="mx-4" inset vertical />
            <v-tooltip v-if="showAddButton" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mr-1"
                  @click="onAddItem"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disableAdd"
                >
                  <v-icon>add</v-icon>Neu
                </v-btn>
              </template>
              <span>Neuer Eintrag</span>
            </v-tooltip>
          </template>
        </template>
      </v-toolbar>
    </template>
    <template v-slot:item="{ item }">
      <tr v-if="filter(item)">
        <td v-for="key in headers" :key="key.value">
          <template v-if="key.type == null || key.type === 'text'">
            <span>{{ displayDefault(key.value, item) }}</span>
          </template>
          <template v-if="key.type === 'checkbox'">
            <v-simple-checkbox
              disabled
              :value="item[key.value]"
              :ripple="false"
            />
          </template>
          <template v-if="key.type === 'datetime'">
            <span>{{ displayDatetime(item[key.value]) }}</span>
          </template>
          <template v-if="key.type === 'actions'">
            <v-row>
              <v-tooltip
                v-if="
                  showEditButton &&
                    item.id &&
                    item.statusdescription != 'eingeladen'
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    x-small
                    fab
                    v-bind="attrs"
                    v-on="on"
                    @click="onEditItem(item)"
                    class="mr-1"
                    :disabled="disableEdit"
                    ><v-icon>edit</v-icon></v-btn
                  >
                </template>
                <span>Datensatz bearbeiten</span>
              </v-tooltip>
              <v-tooltip v-if="showDeleteButton" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    x-small
                    fab
                    v-bind="attrs"
                    v-on="on"
                    @click="onDeleteItem(item)"
                    class="mr-1"
                    :disabled="disableDelete"
                    ><v-icon>delete</v-icon></v-btn
                  >
                </template>
                <span>Datensatz löschen</span>
              </v-tooltip>
              <slot name="actions" v-bind="{ item }"></slot>
            </v-row>
          </template>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    title: String,
    headers: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
    onAdd: Function,
    disableAdd: Boolean,
    onEdit: Function,
    disableEdit: Boolean,
    onDelete: Function,
    disableDelete: Boolean,
    filter: {
      type: Function,
      default: () => true,
    },
    loading: Boolean,
  },
  data: () => {
    return {
      search: "",
    };
  },
  computed: {
    showTitle: function() {
      return this.title != null;
    },

    showButtonBar() {
      return this.showAddButton || this.hasSlot("button-bar");
    },

    showAddButton: function() {
      return this.onAdd != null;
    },

    showEditButton: function() {
      return this.onEdit != null;
    },

    showDeleteButton: function() {
      return this.onDelete != null;
    },
  },
  methods: {
    onAddItem: function() {
      this.onAdd?.(null);
    },

    onEditItem: function(item) {
      this.onEdit?.(item);
    },

    onDeleteItem: function(item) {
      this.onDelete?.(item);
    },

    displayDatetime: function(date) {
      return new Date(date * 1000).toLocaleString("de-DE").slice(0, -3);
    },

    displayDefault: function(value, item) {
      return typeof value === "function" ? value(item) : item[value];
    },

    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
};
</script>
