<template>
    <v-row justify="center">
        <v-col md="12" lg="12" xl="12">
            <v-card elevation="2" class="pa-4" outlined tile>
                <v-card v-if="title != null" elevation="2" class="pa-4 mb-3 text-h6" color="primary lighten-1" dark>
                    {{title}}
                </v-card>
                <v-container fluid>
                    <slot />
                </v-container>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'Box',
        props: [ 'title' ]
    }
</script>

<style scoped>

</style>