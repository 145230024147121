<template>
  <app-dialog
    v-model="show"
    width="80%"
    title="Team bearbeiten"
    subtitle=""
    :onConfirm="$_save"
    :confirmStyle="{
      text: 'Team speichern',
      color: 'primary',
    }"
    :loading="loading"
  >
    <v-form v-model="valid" ref="form">
      <app-form-field title="Name">
        <v-text-field
          v-model="team$.name"
          :rules="[
            (v) => !!v || 'Name erforderlich',
            (v) => (!!v && v.length <= 100) || 'Der Name ist zu lang!',
          ]"
          required
          single-line
          counter="100"
        />
      </app-form-field>
      <app-form-field title="Beschreibung">
        <v-text-field v-model="team$.description" />
      </app-form-field>
      <app-data-table
        :title="table.title"
        :headers="table.headers"
        :items="team$.members"
        :onAdd="openEdit"
        :onEdit="openEdit"
        :onDelete="openDelete"
        :disableDelete="!canDelete"
        :filter="filter"
        :loading="loading"
        class="mx-5"
      >
        <template v-slot:toolbar>
          <v-form ref="form-add" v-model="table.add.valid">
            <v-combobox
              v-model="table.add.emails"
              placeholder="E-Mail Adressen von Teammitgliedern"
              prepend-icon="email"
              append-icon=""
              hide-details
              single-line
              multiple
              chips
              deletable-chips
              disable-lookup
              :rules="[
                (v) => !!v || 'Es wird E-mail benötigt',
                (v) =>
                  /.+@.+\..+/.test(v) ||
                  'Die angegebene E-mail muss gültig sein',
                $_validateAddEmail,
              ]"
              required
              :delimiters="[' ', ',', ';']"
            >
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="confirmAdd"
                      :disabled="!table.add.valid"
                    >
                      <v-icon>add</v-icon>Hinzufügen
                    </v-btn>
                  </template>
                  <span>Mitglieder hinzufügen</span>
                </v-tooltip>
              </template>
            </v-combobox>
          </v-form>
        </template>
      </app-data-table>
      <app-dialog
        v-model="table.edit.show"
        width="45%"
        :title="formEditTitle"
        :confirmStyle="table.edit.confirmButton"
        :onCancel="closeEdit"
        :onConfirm="confirmEdit"
        :disableConfirm="!table.edit.valid"
      >
        <v-form ref="form-edit" v-model="table.edit.valid">
          <v-row justify-center class="px-3">
            <v-text-field
              v-model="table.selectedItem.email"
              :rules="emailRules"
              label="Email"
              placeholder="example@mail.de"
              required
              clearable
              class="mr-5"
            />
            <v-checkbox
              v-model="table.selectedItem.isAdmin"
              label="Teamleitung"
            />
          </v-row>
        </v-form>
      </app-dialog>
      <app-dialog
        v-model="table.delete.show"
        :title="table.delete.title"
        :cancelStyle="table.delete.cancelButton"
        :confirmStyle="table.delete.confirmButton"
        :onCancel="closeDelete"
        :onConfirm="confirmDelete"
      >
        <span>{{ table.selectedItem.email }}</span>
      </app-dialog>
    </v-form>
  </app-dialog>
</template>

<script>
import AppDataTable from "../../components/AppDataTable.vue";
import AppDialog from "../../components/AppDialog.vue";
import AppFormField from "../../components/AppFormField.vue";

export default {
  components: { AppDataTable, AppDialog, AppFormField },
  name: "dialog-team-edit",
  props: {
    value: Boolean,
    team: Object,
   /*  onOpen: Function,
    onClose: Function, */
  },
  data: () => {
    return {
      loading: false,
      valid: false,
      team$: {
        id: -1,
        name: "",
        description: "",
        members: [],
      },
      table: {
        title: "Mitglieder",
        headers: [
          { text: "Email", value: "email" },
          { text: "Status", value: "statusdescription" },
          {
            text: "Teamleitung",
            value: "isAdmin",
            type: "checkbox",
          },
          {
            text: "Aktionen",
            value: "actions",
            sortable: false,
            type: "actions",
          },
        ],
        selectedItem: null,
        selectedIndex: -1,
        min: 2,
        defaultItem: {
          id: -1,
          email: "",
          statusdescription: "eingeladen",
          isAdmin: false,
          added: false,
          changed: false,
          deleted: false,
        },
        add: {
          valid: true,
          max: 5,
          emails: [],
        },
        edit: {
          valid: false,
          show: false,
          confirmButton: {
            text: "Speichern",
            color: "primary",
          },
        },
        delete: {
          show: false,
          title: "Möchten Sie den Eintrag wirklich löschen?",
          cancelButton: {
            text: "Nein",
            color: "normal",
          },
          confirmButton: {
            text: "Ja",
            color: "error",
          },
        },
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    isAdminRules: function() {
      return [this.$_validateIsAdmin];
    },

    emailRules: function() {
      return [
        (v) => !!v || "E-mail wird benötigt",
        (v) => /.+@.+\..+/.test(v) || "Die angegebene E-mail muss gültig sein",
        this.$_validateEmail,
      ];
    },

    canDelete: function() {
      const members = this.team$.members.filter((member) => {
        return !member.deleted;
      });

      return members.length > this.table.min;
    },

    formEditTitle: function() {
      return this.table.selectedIndex > -1 ? "Bearbeiten" : "Hinzufügen";
    },
  },
  watch: {
    value: {
      handler: function(value) {
        value ? this.$_open() : this.$_close();
      },
      deep: true,
    },
  },
  methods: {
    filter(item) {
      return !item.deleted;
    },

    openEdit: function(item) {
      this.table.selectedItem = Object.assign(
        {},
        item ?? this.table.defaultItem
      );
      this.table.selectedIndex = this.team$.members.indexOf(item);
      this.table.edit.show = true;
    },

    closeEdit: function() {
      this.table.edit.show = false;
      this.$nextTick(() => {
        this.$refs["form-edit"].resetValidation();
        this.selectedItem = Object.assign({}, this.table.defaultItem);
        this.selectedIndex = -1;
      });
    },

    confirmEdit: function() {
      this.table.selectedIndex > -1
        ? this.$_editItem(this.table.selectedItem)
        : this.$_addItem(this.table.selectedItem);
      this.closeEdit();

      return false;
    },

    confirmAdd: function() {
      this.table.add.emails.forEach((email) => {
        let item = Object.assign({}, this.table.defaultItem);
        item = Object.assign(item, {
          email: email,
        });

        this.$_addItem(item);
      });

      this.table.add.emails = [];
      this.$refs["form-add"].resetValidation();
    },

    $_editItem: function(item) {
      item.changed = true;
      Object.assign(this.team$.members[this.table.selectedIndex], item);
    },

    $_addItem: function(item) {
      item.added = true;
      this.team$.members.push(item);
    },

    $_validateIsAdmin(isAdmin) {
      const admins = this.team$.members.filter((member, index) => {
        return member.isAdmin && index != this.table.selectedIndex;
      });
      return admins.length >= 1 || isAdmin || "Nicht genug TeamleiterInnen!";
    },

    $_validateAddEmail(emails) {
      var element = emails[emails.length - 1];
      for (let index = 0; index < this.team$.members.length; index++) {
        const member = this.team$.members[index];

        if (member.email === element) {
          return false || "Email exisitiert bereits!";
        }
      }

      return true;
    },

    $_validateEmail(email) {
      const member = this.team$.members.find((member, index) => {
        return member.email === email && index != this.table.selectedIndex;
      });
      return member == null || "Email exisitert bereits";
    },

    openDelete: function(item) {
      this.table.selectedItem = Object.assign({}, item);
      this.table.selectedIndex = this.team$.members.indexOf(item);
      this.table.delete.show = true;
    },

    closeDelete: function() {
      this.table.delete.show = false;
      this.$nextTick(() => {
        this.selectedItem = Object.assign({}, this.table.defaultItem);
        this.selectedIndex = -1;
      });
    },

    confirmDelete: function() {
      this.$_deleteItem(this.table.selectedItem);
      return false;
    },

    $_deleteItem: function(item) {
      item.deleted = true;
      // need to replace so array refresh can be triggered
      this.team$.members.splice(this.table.selectedIndex, 1, item);
    },

    $_open: function() {
      this.team$.id = this.team.id;
      this.team$.name = this.team.name;
      this.team$.description = this.team.description;

      this.$_tryLoading();

      //this.onOpen?.call();
      this.$emit("open");
    },

    $_close: function() {
      this.$refs["form-add"].reset();

      //this.onClose?.call();
      this.$emit("close");
    },

    $_tryLoading: function() {
      try {
        this.loading = true;
        this.call("groups/getGroupMembersWithoutTeamleaderByGroupId", { id: this.team.id }).then(
          (res) => {
            if (!res.success) {
              this.loading = false;
              this.error(res.msg);
              return;
            }

            this.team$.members = res.data;
            this.team$.members.forEach((member) => {
              Object.assign(member, {
                isAdmin: Boolean(parseInt(member.isAdmin)),
                added: false,
                changed: false,
                deleted: false,
              });
            });
            this.loading = false;
          }
        );
      } catch (err) {
        this.error(err);
      }
    },

    $_save() {
      if (!this.valid) {
        this.$refs["form"].validate();
        return true;
      }

      try {
        this.loading = true;
        this.call("groups/ChangeUsersByGroupId", {
          id: this.team.id,
          teamdata: this.team$,
        }).then((res) => {
          if (!res.success) {
            this.error(res.msg);
            return true;
          }

          this.loading = false;
          return false;
        });
      } catch (err) {
        this.error(err);
        return true;
      }
    },
  },
  created() {
    this.table.selectedItem = Object.assign({}, this.table.defaultItem);
  },
};
</script>