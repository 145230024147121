<template>
    <v-form v-if="fields != null" v-on:submit.prevent="() => {}" v-model="formValid">
        <v-row v-if="title != null">
            <v-col cols="5">
                <span class="text-h6">{{title}}</span>
            </v-col>
        </v-row>
        <v-row dense v-for="(field, i) in fields" :key="i">
            <v-col cols="4">
                <v-subheader>{{field.text}}</v-subheader>
            </v-col>
            <v-col v-if="field.type == null || field.type == 'text'">
                <v-text-field v-model="field.value" :readonly="!showEdit || field.disabled" :loading="loading" :disabled="loading || field.readonly || field.disabled" :rules="field.rules" :required="field.required"></v-text-field>
            </v-col>
            <v-col v-if="field.type != null">
                <v-select v-if="field.type == 'select'" v-model="field.value" :items="field.loadedData" :item-text="field.select.text" :item-value="field.select.value" :readonly="!showEdit || field.disabled" :loading="loading" :disabled="loading || field.readonly || field.disabled" :rules="field.rules" :required="field.required"></v-select>
                <v-textarea v-if="field.type == 'textarea'" v-model="field.value" :readonly="!showEdit || field.disabled" :loading="loading" :disabled="loading || field.readonly || field.disabled" :rules="field.rules" :required="field.required"></v-textarea>
                <v-checkbox v-if="field.type == 'checkbox'" v-model="field.value" :loading="loading" :disabled="loading || field.readonly || field.disabled" :rules="field.rules" :required="field.required"/>
                <DateTimePicker v-if="field.type == 'datetime'" v-model="field.value" v-bind="field.datetime" :loading="loading" :disabled="loading || field.readonly || field.disabled" :rules="field.rules" :required="field.required" />
            </v-col><!-- :min="field.datetime != null ? field.datetime.min != null ? field.datetime.min(fields) : field.datetime.min : null" -->
        </v-row>
        <v-row v-if="nobuttons == null">
            <v-col>
                <v-btn v-show="!showEdit" color="primary" dark class="ma-2 text-subtitle-2 text-capitalize" @click="editForm()"><v-icon left>edit</v-icon> Bearbeiten</v-btn>
                <v-btn v-show="showEdit" color="primary" dark class="ma-2 text-subtitle-2 text-capitalize" @click="saveForm()" :loading="loading" :disabled="loading"><v-icon left>save</v-icon> Speichern</v-btn>
                <v-btn v-show="showEdit" color="error" dark class="ma-2 text-subtitle-2 text-capitalize" @click="resetForm()" :disabled="loading"><v-icon left>clear</v-icon> Verwerfen</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    export default {
        props: [ 'value', 'fields', 'title', 'editonly', 'api_read', 'api_write', 'nobuttons', 'valid' ],
        data: function (){
            return {
                edit: false,
                loading: false,
                formValid: false
            }
        },
        computed: {
            showEdit(){
                return this.editonly || this.edit;
            }
        },
        methods: {
            readValues() {
                for(var f in this.fields){
                    if(this.fields[f].data != null){
                        this.loadData(this.fields[f]);
                    }
                    else if(this.value != null && this.value[this.fields[f].var]) {
                        this.fields[f].value = this.value[this.fields[f].var];
                    }
                }
            },
            writeValues() {
                if(this.value != null){
                    console.log("write values back");
                    for(const f in this.fields){
                        if(!this.fields[f].disabled){
                            this.value[this.fields[f].var] = this.fields[f].value;
                        }
                    }
                }
                else {
                    var tmp = {};
                    console.log("write values back to new value");
                    for(const f in this.fields){
                        if(!this.fields[f].disabled){
                            tmp[this.fields[f].var] = this.fields[f].value;
                        }
                    }
                    this.$emit('update:value', tmp);
                }
            },
            loadForm(){
                this.loading = true;
                if(this.api_read){
                    this.call(this.api_read).then(res => {
                        console.log(res);
                        if(res.success){
                            this.value = res.data;
                        }
                        else {
                            //todo show read error
                            //this.setError(res.msg);
                        }
                        this.loading = false;
                        this.readValues();
                    });
                }
                else {
                    this.loading = false;
                    this.readValues();
                }
            },
            resetForm(){
                this.readValues();
                this.edit = false;

                if(this.editonly){
                    this.$emit('update:editonly', false)
                    console.log("change editonly to false");
                }
                    
            },
            editForm(){
                this.edit = true;
            },
            saveForm(){
                this.loading = true;
                if(this.api_write){
                    var postData = {};

                    
                    for(var f in this.fields){
                        if(!this.fields[f].disabled && this.fields[f].value != null){
                            postData[this.fields[f].var] = this.fields[f].value;
                        }
                    }
                    console.log(postData);

                    this.call(this.api_write, postData).then(res => {
                        console.log(res);
                        if(res.success){
                            this.writeValues();
                        }
                        else {
                            //todo show write error
                            this.error(res.msg);
                        }
                        this.loading = false;
                        this.resetForm();
                    });
                }
                else { 
                    this.writeValues();
                    this.loading = false;
                    this.resetForm();
                }
            },
            loadData(field){
                if(field.loadedData == null){
                    field.loadedData = [];
                    this.call(field.data).then(res => {
                        console.log("got data");
                        console.log(res);
                        if(res.success){
                            if(field.filter != null){
                                field.loadedData = res.data.filter(field.filter); 
                            }  
                            else {
                                field.loadedData = res.data;
                            }
                            
                            if(this.value != null && this.value[field.var]){
                                field.value = this.value[field.var];
                            }
                            else if(res.data.length > 0) {
                                if(field.type == 'select'){
                                    field.value = res.data[0][field.select.value];
                                }
                            }
                            //updated data
                            console.log(field);
                        }
                        else {
                            this.error(res.msg);
                        }
                    });
                }
                else if(this.value != null && this.value[field.var]){
                    field.value = this.value[field.var];
                }
            }
        },
        watch: {
            value(){
                console.log("value changed");
                this.readValues();
            },
            formValid () {
                console.log("valid changed");
                this.$emit('update:valid', this.formValid);
            }
        },
        mounted() {
            console.log("EditForm loaded");
            this.loadForm();
        }
    }
</script>

<style scoped>

</style>