<template>
  <v-container
    v-show="!hideEmpty || (hideEmpty && data != null && data.length > 0)"
    fluid
  >
    <v-row v-if="title != null">
      <v-col cols="5">
        <span class="text-h6">{{ title }}</span>
      </v-col>
    </v-row>
    <v-row v-if="subtitle != null">
      <v-col cols="8">
        <span class="text-h7">{{ subtitle }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="createfields != null"
          color="primary"
          dark
          class="ma-2 text-subtitle-2 text-capitalize"
          @click="showCreate = true"
          ><v-icon left>add</v-icon> Neu</v-btn
        >
        <slot name="buttonbar" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card tile>
          <v-card-title v-show="showFilter && data != null">
            <v-text-field
              :disabled="loading"
              v-model="search"
              append-icon="mdi-magnify"
              label="Filter"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="data"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            :group-by="group"
            :sort-by="sort"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td
                  v-for="key in headers"
                  :key="key.value"
                  :class="
                    key.align == null || key.align == 'start'
                      ? 'text-start'
                      : 'text-end'
                  "
                >
                  <span v-if="key.type == null || key.type == 'text'">{{
                    typeof key.value == "function"
                      ? key.value(item)
                      : item[key.value]
                  }}</span>
                  <v-simple-checkbox
                    v-if="key.type == 'checkbox'"
                    v-model="item[key.value]"
                    disabled
                  ></v-simple-checkbox>
                  <span v-if="key.type == 'datetime'">{{
                    new Date(item[key.value] * 1000)
                      .toLocaleString("de-DE")
                      .slice(0, -3)
                  }}</span>

                  <v-tooltip
                    v-if="key.value == 'actions' && editfields != null"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        small
                        fab
                        @click="editItem(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>edit</v-icon></v-btn
                      >
                    </template>
                    <span>bearbeiten</span>
                  </v-tooltip>
                  <!-- custom actions -->
                  <slot v-if="key.value == 'actions'" v-bind="item" />

                  <v-tooltip
                    v-if="
                      key.value == 'actions' &&
                        deletefield != null &&
                        (deleteif == null || deleteif(item))
                    "
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        small
                        fab
                        @click="deleteItem(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>delete</v-icon></v-btn
                      >
                    </template>
                    <span>löschen</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showCreate" max-width="600px">
      <v-card tile>
        <v-card-title>
          <span>{{ title }} anlegen</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="showCreate = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <EditForm
            :editonly.sync="showCreate"
            :value.sync="newItem"
            :fields="createfields"
            :api_write="api_create"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-if="item != null" v-model="showEdit" max-width="600px">
      <v-card tile>
        <v-card-title>
          <span
            >{{ title
            }}{{
              item.name != null ? " '" + item.name + "'" : ""
            }}
            bearbeiten</span
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="showEdit = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <EditForm
            :editonly.sync="showEdit"
            v-model="item"
            :fields="editfields"
            :api_write="api_edit"
          />
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirm" max-width="22%">
      <v-card>
        <v-card-title class="headline">
          {{ title }} wirklich löschen?
        </v-card-title>
        <!--v-card-text>
                    
                </v-card-text-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirm = false">Nein</v-btn>
          <v-btn color="red" text @click="confirmFunc()">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: [
    "hasData",
    "hidewithnodata",
    "nofilter",
    "reload",
    "loadeddata",
    "value",
    "title",
    "subtitle",
    "headers",
    "api_load",
    "createfields",
    "api_create",
    "editfields",
    "api_edit",
    "deletefield",
    "api_delete",
    "deleteif",
    "group",
    "sort",
  ],
  data: function() {
    return {
      loading: false,
      showEdit: false,
      showCreate: false,
      item: null,
      newItem: null,
      data: [],
      search: null,
      confirm: false,
      confirmFunc: () => {},
    };
  },
  computed: {
    showFilter() {
      return this["nofilter"] == null;
    },
    hideEmpty() {
      return this["hidewithnodata"] != null;
    },
  },
  methods: {
    editItem(item) {
      this.item = item;
      this.showEdit = true;
    },
    deleteItem(item) {
      this.confirmFunc = () => {
        if (this.api_delete) {
          this.call(
            this.api_delete +
              "?" +
              this.deletefield +
              "=" +
              item[this.deletefield]
          ).then((res) => {
            if (res.success) {
              this.loadData();
            } else {
              this.error(res.msg);
            }
          });
        } else {
          var i = this.data.findIndex((e) => {
            e[this.deletefield] == item[this.deletefield];
          });
          this.data.splice(i, 1);
          if (this.value != null) {
            this.value = this.data;
          }
          this.loadData();
        }
        this.confirm = false;
      };
      this.confirm = true;
    },
    loadData() {
      if (this.api_load) {
        this.loading = true;
        this.call(this.api_load).then((res) => {
          //console.log("EditTable: ", res);
          if (res.success) {
            if (typeof res.data == "object") {
              this.data = Object.values(res.data);
            } else this.data = res.data;
            /*this.data.forEach(company => {
                                company.actions = 'edit';
                            });*/
            this.$emit("update:loadeddata", this.data);
          } else {
            this.error(res.msg);
          }
          this.loading = false;
        });
      } else if (this.value != null) {
        this.data = this.value;
      }
    },
  },
  watch: {
    value() {
      if (this.value != null) {
        console.log("value changed");
        this.loadData();
      } else this.data = [];
    },
    showEdit() {
      if (!this.showEdit) {
        this.loadData();
      }
    },
    item() {
      if (this.item != null) {
        console.log("item edit changed");
      }
    },
    data() {
      this.$emit("update:hasData", this.data != null);
    },
    newItem() {
      if (this.newItem != null) {
        console.log("new item added");
        //console.log(this.newItem);
        this.data.push(this.newItem);
        this.newItem = null;
        this.loadData();
      }
    },
    api_load() {
      console.log("api load changed");
      this.loadData();
    },
    reload() {
      if (this.reload) {
        console.log("reload triggered");
        this.loadData();
        this.$emit("update:reload", false);
        this.$emit("reload");
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
