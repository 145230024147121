<template>
    <v-container>
        <EditForm :fields="fields" title="Tooleinstellungen" api_read="company/getcompany" api_write="company/SetLevelOfDetail" />
    </v-container>
</template>

<script>
    export default {
        data: function (){
            return {
                fields: [
                    { text: 'Detailgrad', var: 'detailid', value: null, data: 'company/ListLevelsOfDetails', type: 'select', select: { text: 'name', value: 'id' } },
                    //{ text: 'Unternehmen', var: 'companyid', value: null, disabled: true, data: 'system/getcompanies', type: 'select', select: { text: 'name', value: 'id' } },
                ]
            }
        },
    }
</script>

<style scoped>

</style>