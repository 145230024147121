<template>
    <v-container>
        <EditTable :reload.sync="reload" title="Fragen" :headers="cols" api_load="questions/ListQuestions" :createfields="create" api_create="questions/CreateQuestion" :editfields="edit" api_edit="questions/ChangeQuestion" >
            <!--template v-slot:default="item"-->
                <!--v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  small fab @click="archive(item)" v-bind="attrs" v-on="on"><v-icon>archive</v-icon></v-btn> 
                    </template>
                    <span>Team archivieren</span>
                </v-tooltip-->
            <!--/template-->
        </EditTable>
    </v-container>
</template>

<script>
    export default {
        data: function() {
            return {
                reload: false,
                cols: [
                    { text: 'ID', value: 'id' },
                    { text: 'Text', value: 'text' },
                    //{ text: 'AntwortTyp', value: 'Answertype' },
                    { text: 'Kategorie', value: 'Category' },
                    { text: 'SubKategorie', value: 'SubCategory' },
                    { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
                ],
                create: [
                    /*{ text: 'ID', var: 'companyid', value: null, readonly: true },*/
                    { text: 'Text', var: 'text', value: null, type: "textarea" },
                    { text: 'AntwortTyp', var: 'Answertyp', value: 1, readonly: true },
                    //{ text: 'Kategorie', var: 'CategoryID', value: null, data: 'questions/ListCategorys', type: 'select', select: { text: 'Name', value: 'Id' } },
                    { text: 'SubKategorie', var: 'SubCategoryID', value: null, data: 'questions/ListSubcategorys', type: 'select', select: { text: 'name', value: 'id' } },
                ],
                edit: [
                    { text: 'ID', var: 'id', value: null, readonly: true },
                    { text: 'Text', var: 'text', value: null, type: "textarea" },
                    { text: 'AntwortTyp', var: 'Answertyp', value: null, readonly: true },
                    //{ text: 'Kategorie', var: 'CategoryID', value: null, data: 'questions/ListCategorys', type: 'select', select: { text: 'Name', value: 'Id' } },
                    { text: 'SubKategorie', var: 'SubCategoryID', value: null, data: 'questions/ListSubcategorys', type: 'select', select: { text: 'name', value: 'id' } },
                ],
            }
        },
        methods: {
            archive(item) {
                this.call('groups/ArchiveGroup', { groupid: item.id }).then(res => {
                    console.log(res);
                    if(res.success){
                        this.reload = true;
                    }
                    else {
                        this.setError(res.msg);
                    }
                });
            }
        },
        watch: {

        },
        mounted () {

        }
    }
</script>

<style scoped>

</style>