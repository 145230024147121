<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12" lg="8" xl="6">
                <v-card elevation="2" class="pa-4" outlined tile>
                    <v-card elevation="2" class="pa-4 mb-3 text-h6" color="primary lighten-1" dark>Registration</v-card>
                    <v-container>
                        <v-form ref="registerform" v-model="registerformValid" v-on:submit.prevent="onSubmit">
                        <!-- <v-form ref="registerform" v-model="registerformValid"> -->
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="reg_email" v-model="reg_email" :rules="emailRules" label="E-Mail Adresse *" required :disabled="attemptRegister">
                                        <v-icon slot="append">mail_outline</v-icon>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="firstname" v-model="firstname" :rules="nameRules" label="Vorname *" required :disabled="attemptRegister">
                                        <v-icon slot="append">badge</v-icon>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" dense>
                                <v-col md="7">
                                    <v-text-field id="lastname" v-model="lastname" :rules="nameRules" label="Nachname *" required :disabled="attemptRegister">
                                        <v-icon slot="append">badge</v-icon>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row v-show="error != null" justify="center" dense>
                                <v-col md="7">
                                    <v-alert dense type="error">
                                        Error: <strong>{{error}}</strong>
                                    </v-alert>
                                </v-col>
                            </v-row>
                            
                            <v-row justify="center">
                                <v-col md="4">
                                    <v-btn type="submit" color="primary" block class="ma-2 text-subtitle-2 text-capitalize" :disabled="!registerformValid" :loading="attemptRegister" @click="register()">Registrieren</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row justify="center" dense>
                            <v-col md="4" class="px-4">
                                <v-btn text @click="$router.push('/login')" block>Zurück zum Login</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: function() {
            return {
                emailRules: [
                    v => !!v || 'E-Mail erforderlich',
                    v => /.+@.+\..+/.test(v) || 'E-Mail nicht gültig',
                ],
                nameRules: [
                    v => !!v || 'erforderlich',
                ],
                error: null,
                attemptRegister: false,
                registerformValid: false,
                reg_email: null,
                firstname: null,
                lastname: null,
            }
        },
        methods: {
            onSubmit() {
                if(this.$refs.registerform.validate()){
                    this.register();
                }
            },
            register: function() {
                this.attemptRegister = true;
                this.call('users/CreateUser', {
                    email: this.reg_email,
                    firstname: this.firstname,
                    lastname: this.lastname,
                }).then(res => {
                    this.attemptRegister = false;
                    console.log("attempted register");
                    console.log(res);
                    
                    if(res.success){
                        this.$router.push('/login');
                    }
                    else {
                        this.error = res.msg; //show error
                    }
                })
            }
        },
        created () {

        }
    }
</script>

<style scoped>

</style>