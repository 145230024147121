<template>
  <v-dialog v-model="show" :max-width="width">
    <v-card>
      <v-card-title>
        <span>{{ title }}</span>
        <v-spacer />
        <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon> </v-btn>
      </v-card-title>
      <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          :disabled="disableCancel"
          :color="cancelStyle.color"
          @click="_onCanceled"
          >{{ cancelStyle.text }}
        </v-btn>
        <v-btn
          text
          :disabled="disableConfirm"
          :color="confirmStyle.color"
          @click="_onConfirmed"
          >{{ confirmStyle.text }}
        </v-btn>
      </v-card-actions>
      <v-fade-transition>
        <v-overlay :absolute="true" :value="loading" :opacity="0.75">
          <v-card-text class="my-15">
            <v-row justify="center">
              <v-progress-circular :size="75" color="primary" indeterminate />
            </v-row>
          </v-card-text>
        </v-overlay>
      </v-fade-transition>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    subtitle: String,
    /*   onOpen: Function,
    onClose: Function,*/
    onConfirm: Function,
    onCancel: Function,
    disableConfirm: Boolean,
    disableCancel: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
    cancelStyle: {
      type: Object,
      default: () => {
        return {
          text: "Abbrechen",
          color: "normal",
        };
      },
    },
    confirmStyle: {
      type: Object,
      default: () => {
        return {
          text: "Bestätigen",
          color: "primary",
        };
      },
    },
    width: {
      type: String,
      default: () => "40%",
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value: {
      handler: function(value) {
        value ? this._onOpen() : this._onClose();
      },
      deep: true,
    },
  },
  methods: {
    _onConfirmed() {
      this.show = this.onConfirm?.();
    },
    _onCanceled() {
      this.show = this.onCancel?.();
    },
    _onOpen() {
      //this.onOpen?.();
      this.$emit("open");
    },
    _onClose() {
      //this.onClose?.();
      this.$emit("close");
    },
  },
};
</script>
