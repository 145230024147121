<template>
  <v-container fluid>
    <Box>
      <div id="logo_home" />
    </Box>
    <Box
      :title="
        `Herzlich willkommen, ${
          $store.userData.title != null ? `${$store.userData.title} ` : ''
        }${$store.userData.firstname} ${$store.userData.lastname}!`
      "
    >
      <p>
        Der TeamCheck ist ein Tool zur Analyse der gemeinsamen Vorstellungen zu
        Schlüsselelementen und –prozessen im Team (sog. Teamkognitionen). Die
        Ergebnisse helfen dabei, Unklarheiten und Informationsbedarfe
        aufzudecken. Damit unterstützt Sie TeamCheck bei der gemeinsamen und
        kontinuierlichen Reflexion, die letztendlich Ihre Teamarbeit effektiver
        macht.
      </p>
      <p>
        Starten Sie Ihren TeamCheck und erstellen Sie für Ihr Team eine eigene
        Umfrage – schnell und unkompliziert. Klicken Sie dafür im Menü links auf
        „Teams“, um Ihr Team anzulegen, und lassen Sie sich in wenigen Schritten
        durch die Erstellung einer Umfrage führen.
      </p>
      <p>
        Viel Spaß und Erfolg dabei!
      </p>
    </Box>

    <InvitesTable
        :reload.sync="reloadInvitesTable"
        @action="
          (e) => {
            if (e) this.reload = true;
          }
        "
      />
    <!---
    <Box title="Teams" v-show="showInvites">
      <InvitesTable v-model="showInvites" />
    </Box>
    -->

    <Box title="Offene Umfragen">
      <p>
        Hier sehen Sie die aktuellen Umfragen, die für Sie zur Beantwortung
        offen sind.
      </p>
      <EditTable
        :reload.sync="reloadSurveys"
        :headers="tableHeadersSurvey"
        api_load="surveys/ListMySurveys/0/0/2"
      >
        <template v-slot:default="item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }"
              ><!-- :disabled="!item.IsActive4User" -->
              <v-btn
                color="primary"
                small
                fab
                @click="startSurvey(item)"
                v-bind="attrs"
                v-on="on"
                :disabled="!item.IsStartable4User && !item.IsActive4User"
                ><v-icon>{{
                  item.IsStartable4User
                    ? "assignment"
                    : item.IsActive4User
                    ? "pending_actions"
                    : "assignment_turned_in"
                }}</v-icon></v-btn
              >
            </template>
            <span>{{
              item.IsActive4User ? "Teilnahme fortsetzen" : "teilnehmen"
            }}</span>
          </v-tooltip>
        </template>
      </EditTable>
      <SurveyAnswerDialog
        v-model="showSurveyAnswerDialog"
        :survey="activeSurvey"
      />
    </Box>
    <!--Box title="Einladungen" >
      <p>
        Hier sehen Sie die aktuellen Umfragen, die für Sie zur Beantwortung offen sind.
      </p>
      <p>
        Sie können auch als Teammitglied eine Umfrage in Ihrem Team anfordern.
      </p>
      <EditTable title="Umfragen" :headers="cols" api_load="surveys/ListMySurveys" >

      </EditTable>
    </Box-->
    <Box title="Wissenswertes">
      <p>
        Sie möchten Ihr Wissen rund um die im TeamCheck erhobenen
        Teamkognitionen und Teamprozesse noch weiter vertiefen?
      </p>
      <p>
        Viel Spaß beim Stöbern!
      </p>
      <v-btn
        v-for="(item, i) in knowledgeButtons"
        :key="i"
        color="primary"
        dark
        class="ma-2 text-subtitle-2 text-capitalize"
        @click="viewPdf(item.text, item.pdf)"
        >{{ item.text }}</v-btn
      >
    </Box>
    <Box title="Tipps & Tricks">
      <p>
        Sie suchen nach Tipps & Tricks, wie Sie Ihre digitale Zusammenarbeit in
        der Praxis stärken können?
      </p>
      <p>
        Hier haben wir einiges für Sie zusammengestellt.
      </p>
      <v-btn
        v-for="(item, i) in hintButtons"
        :key="i"
        color="primary"
        dark
        class="ma-2 text-subtitle-2 text-capitalize"
        @click="viewPdf(item.text, item.pdf)"
        >{{ item.text }}</v-btn
      >
    </Box>
    <Box>
      <div id="logo_sponsor" />
    </Box>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: function() {
    return {
      showInvites: false,
      reloadInvitesTable: false,
      showSurveyAnswerDialog: false,
      activeSurvey: null,
      reloadSurveys: false,
      tableHeadersSurvey: [
        { text: "Umfrage", value: "name", align: "start" },
        { text: "Team", value: "groupname" },
        { text: "Start", value: "startdate", type: "datetime" },
        { text: "Ende", value: "enddate", type: "datetime" },
        { text: "Aktion", sortable: false, value: "actions" },
      ],
      knowledgeButtons: [
        {
          text: "Teamkognitionen",
          pdf: this.apihostname + "/pdfs/TC_Teamkognitionen.pdf",
        },
        {
          text: "Teamprozesse",
          pdf: this.apihostname + "/pdfs/TC_Teamprozesse.pdf",
        },
        {
          text: "Arbeitsbelastung",
          pdf: this.apihostname + "/pdfs/TC_Arbeitsbelastung.pdf",
        },
      ],
      hintButtons: [
        {
          text: "Besprechung der TeamCheck Ergebnisse",
          pdf: this.apihostname + "/pdfs/TC_Besprechung-Ergebnisse.pdf",
        },
        {
          text: "Führung virtueller bzw. hybrider Teams",
          pdf: this.apihostname + "/pdfs/TC_Führung-virtueller-Teams.pdf",
        },
        {
          text: "Gestaltung von Online-Meetings",
          pdf: this.apihostname + "/pdfs/TC_Online-Meetings.pdf",
        },
      ],
    };
  },
  methods: {
    startSurvey(item) {
      this.activeSurvey = item;
      this.showSurveyAnswerDialog = true;
    },
  },
  watch: {
    reload() {
      if (!this.reload) return;
      this.reloadInvitesTable = true;
      this.reload = false;
    },
    showSurveyAnswerDialog() {
      if (this.showSurveyAnswerDialog == false) {
        this.reloadSurveys = true;
      }
    },
  },
};
</script>

<style scoped>
#logo_home {
  width: 100%;
  height: 250px;
  background-image: url(~@/assets/vlead-home.jpg);
  background-size: cover;
}

#logo_sponsor {
  margin: auto 20px;
  height: 196px;
  background-image: url(~@/assets/logo-foerderer-col.png);
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
