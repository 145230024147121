<template>
    <v-container>
        <EditTable :reload.sync="reload" title="Fragenkategorien" :headers="cols" api_load="questions/ListCategorys" :createfields="create" api_create="questions/CreateCategory" :editfields="edit" api_edit="questions/ChangeCategory" >

        </EditTable>
        <EditTable :reload.sync="reload" title="Fragensubkategorien" :headers="cols_sub" api_load="questions/ListSubcategorys" :createfields="create_sub" api_create="questions/CreateSubcategory" :editfields="edit_sub" api_edit="questions/ChangeSubcategory" >

        </EditTable>
    </v-container>
</template>

<script>
    export default {
        data: function() {
            return {
                reload: false,
                cols: [
                    { text: 'ID', value: 'id' },
                    { text: 'Name', value: 'name' },
                    { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
                ],
                create: [
                    /*{ text: 'ID', var: 'companyid', value: null, readonly: true },*/
                    { text: 'Name', var: 'name', value: null },
                ],
                edit: [
                    { text: 'ID', var: 'id', value: null, readonly: true },
                    { text: 'Name', var: 'name', value: null }
                ],
                cols_sub: [
                    { text: 'ID', value: 'id' },
                    { text: 'Name', value: 'name' },
                    { text: 'questioncategoryid', value: 'questioncategoryid' },
                    { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
                ],
                create_sub: [
                    /*{ text: 'ID', var: 'companyid', value: null, readonly: true },*/
                    { text: 'Name', var: 'name', value: null },
                    { text: 'Kategorie', var: 'questioncategoryid', value: null, data: 'questions/ListCategorys', type: 'select', select: { text: 'name', value: 'id' } },
                    { text: 'Description', var: 'description', value: null, type: "textarea" },
                    { text: 'ResultDescription', var: 'resultdescription', value: null, type: "textarea" },
                ],
                edit_sub: [
                    { text: 'ID', var: 'id', value: null, readonly: true },
                    { text: 'Name', var: 'name', value: null },
                    { text: 'Kategorie', var: 'questioncategoryid', value: null, data: 'questions/ListCategorys', type: 'select', select: { text: 'name', value: 'id' } },
                    { text: 'Description', var: 'description', value: null, type: "textarea" },
                    { text: 'ResultDescription', var: 'resultdescription', value: null, type: "textarea" },
                ],
            }
        },
        methods: {
            archive(item) {
                this.call('groups/ArchiveGroup', { groupid: item.id }).then(res => {
                    console.log(res);
                    if(res.success){
                        this.reload = true;
                    }
                    else {
                        this.setError(res.msg);
                    }
                });
            }
        },
        watch: {

        },
        mounted () {

        }
    }
</script>

<style scoped>

</style>