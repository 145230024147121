<template>
    <v-layout fill-height column>
        <!--v-flex shrink v-show="$vuetify.breakpoint.mdAndDown">
            <v-toolbar>
                <v-col v-show="$vuetify.breakpoint.mdAndUp" cols="2">Kategorien</v-col>
                <v-toolbar-items :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : 'pa-0'">
                    <v-slide-group v-model="selectedCategory" :show-arrows="$vuetify.breakpoint.smAndUp" :style="$vuetify.breakpoint.mdAndDown ? 'max-width: 390px;' : ''">
                        <v-slide-item v-for="(cat, i) in categorieResults" :key="i" v-slot="{ active, toggle }">
                            <v-btn @click="toggle" height="100%" :disabled="active">
                                <v-img :src="toBase64SVG(cat.svg)" contain height="64px" width="256px" />
                            </v-btn>
                        </v-slide-item>
                    </v-slide-group>
                </v-toolbar-items>
            </v-toolbar>
            <v-toolbar>
                <v-col v-show="$vuetify.breakpoint.mdAndUp" cols="2">Subkategorien</v-col>
                <v-toolbar-items class="ml-2">
                    <v-slide-group v-model="selectedSubcategory" :show-arrows="$vuetify.breakpoint.smAndUp" :style="$vuetify.breakpoint.mdAndDown ? 'max-width: 390px;' : ''">
                        <v-slide-item v-for="(subcat, i) in subcategoriesResults" :key="i" v-slot="{ active, toggle }">
                            <v-btn @click="toggle" height="100%" :disabled="active">
                                <v-img :src="toBase64SVG(subcat.svg)" contain height="64px" width="256px" />
                            </v-btn>
                        </v-slide-item>
                    </v-slide-group>
                </v-toolbar-items>
            </v-toolbar>
        </v-flex-->
        <v-layout>
            <v-flex shrink v-show="$vuetify.breakpoint.lgAndUp">
                <v-navigation-drawer permanent bottom>
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-title>
                                Kategorien
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item-group v-model="selectedCategory" mandatory>
                            <v-list-item v-for="(cat, i) in categorieResults" :key="i">
                                <v-list-item-title>
                                    <v-img :src="toBase64SVG(cat.svg)" contain />
                                </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-navigation-drawer>
            </v-flex>
            <v-flex shrink v-show="$vuetify.breakpoint.lgAndUp">
                <v-navigation-drawer permanent>
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-title>
                                Subkategorien
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item-group v-model="selectedSubcategory" mandatory>
                            <v-list-item v-for="(subcat, i) in subcategoriesResults" :key="i">
                                <v-list-item-title>
                                    <v-img :src="toBase64SVG(subcat.svg)" contain />
                                </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-navigation-drawer>
            </v-flex>
            <v-flex v-if="selectedSubcategory != null">
                <v-toolbar v-show="$vuetify.breakpoint.mdAndDown" extension-height="64" dense>
                    <v-col>Kategorie</v-col>
                    <template v-slot:extension>
                    <!--v-toolbar-items :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : 'pa-0'"-->
                        <v-slide-group v-model="selectedCategory" :show-arrows="true">
                            <v-slide-item v-for="(cat, i) in categorieResults" :key="i" v-slot="{ active, toggle }">
                                <v-btn @click="toggle" height="100%" :disabled="active">
                                    <v-img :src="toBase64SVG(cat.svg)" contain height="64px" width="256px" />
                                </v-btn>
                            </v-slide-item>
                        </v-slide-group>
                    <!--/v-toolbar-items-->
                    </template>
                </v-toolbar>
                <v-divider v-show="$vuetify.breakpoint.mdAndDown" />
                <v-toolbar v-show="$vuetify.breakpoint.mdAndDown" extension-height="64" dense>
                    <v-col>Subkategorie</v-col>
                    <template v-slot:extension>
                    <!--v-toolbar-items class="ml-2"-->
                        <v-slide-group v-model="selectedSubcategory" :show-arrows="true" style="width: 100%">
                            <v-slide-item v-for="(subcat, i) in subcategoriesResults" :key="i" v-slot="{ active, toggle }">
                                <v-btn @click="toggle" height="100%" :disabled="active" tile>
                                    <v-img :src="toBase64SVG(subcat.svg)" contain height="64px" width="256px" />
                                </v-btn>
                            </v-slide-item>
                        </v-slide-group>
                    <!--/v-toolbar-items-->
                    </template>
                </v-toolbar>
                <v-divider v-show="$vuetify.breakpoint.mdAndDown" />
                <!--v-container  fluid class="pa-0"-->
                    <v-card tile v-if="questionResult != null && subcategoriesResults[selectedSubcategory] != null">
                        <v-card-title>
                            {{subcategoriesResults[selectedSubcategory].title}} ({{subcategoriesResults[selectedSubcategory].uniqueusers}} Teilnehmer)
                        </v-card-title>
                        <v-card-text>
                            <v-card>
                                <v-card-text>
                                    {{questionResult.SubCatDescription}}
                                </v-card-text>
                            </v-card>
                            <v-card class="my-2">
                                <v-card-text>
                                    <v-flex shrink grow>
                                        <!--img v-show="$vuetify.breakpoint.smAndDown" :src="toBase64SVG(questionResult.svg)" style="width: 100%" contain /-->
                                        <object :data="toBase64SVG(questionResult.svg)" type="image/svg+xml" />
                                    </v-flex>
                                </v-card-text>
                            </v-card>
                            <HelpBox :items="[ 'Reflexionsfragen' ]" :value="0">
                                <template slot=0>
                                    <div v-html='questionResult.SubCatReflection'/>
                                </template>
                            </HelpBox>
                        </v-card-text>
                    </v-card>
                <!--/v-container-->
            </v-flex>
        </v-layout>
    </v-layout>
</template>

<script>
export default {
    props: [ "randomId" ],
    data () {
        return {
            categorieResults: [],
            selectedCategory: null,
            subcategoriesResults: [],
            selectedSubcategory: null,
            questionResult: null,
        }
    },
    methods: {
        toBase64SVG(svg){
            return 'data:image/svg+xml;base64,' + btoa(svg);
        },
    },
    watch: {
        selectedCategory(value){
            if(this.categorieResults[value] != null){
                console.log(`selectedCategory: ${value}, ${this.categorieResults[value].id}`);
                this.call("results/getSurveySubCategoryResultRnd", { randomId: this.randomId, categoryid: this.categorieResults[value].id }).then(res => {
                    if(res.success){
                        console.log(`selectedCategory: changeSubcats`);
                        this.subcategoriesResults = res.data;
                        this.selectedSubcategory = -1;
                    }
                })
            }
        },
        selectedSubcategory(value){
           if(this.subcategoriesResults[value] != null){
               console.log(`selectedSubcategory: ${value}, ${this.subcategoriesResults[value].id}`);
                this.call("results/getSurveySCQuestionResultRnd", { randomId: this.randomId, subcatid: this.subcategoriesResults[value].id }).then(res => {
                    if(res.success){
                        console.log(res.data);
                        this.questionResult = res.data;
                    }
                })
           }
        }
    },
    mounted() {
        this.call("results/getSurveyCategoryResultRnd", { randomId: this.randomId }).then(res => {
            if(res.success){
                this.categorieResults = res.data;
            }
        })
    }
}
</script>

<style scoped>

</style>