<template>
  <v-app>
    <v-navigation-drawer
      v-if="$store.initialized && $store.loggedin"
      v-model="menu"
      app
    >
      <v-list color="primary" class="pt-4" dark>
        <v-list-item id="avatar" class="rounded-lg">
          <!-- v-img cover src="@/assets/user-example.jpg" height="80px" width="80px" /-->
          <v-img cover :src="$store.avatar" height="80px" width="80px" />
        </v-list-item>

        <p class="text-center mt-1">
          {{ $store.userData.title != null ? `${$store.userData.title} ` : ""
          }}{{ $store.userData.firstname }} {{ $store.userData.lastname }}
        </p>
      </v-list>

      <v-divider></v-divider>

      <v-list dense class="mt-4">
        <v-list-item
          link
          v-for="(item, i) in menuItems"
          :key="i"
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>

        <v-divider class="mt-5"></v-divider>
        <v-list-item link to="/settings">
          <v-list-item-icon>
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Einstellungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list dense>
          <v-list-item
            link
            v-for="(item, i) in bottomMenuItems"
            :key="i"
            v-bind="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- { name: 'Datenschutz', icon: 'fingerprint', link: { href: '#' } }, -->
          <v-list-item
            link
            @click="
              viewPdf(
                'Datenschutzerklärung',
                this.apihostname + '/pdfs/Datenschutzerklaerung.pdf'
              )
            "
          >
            <v-list-item-icon>
              <v-icon>fingerprint</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Datenschutz</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--   { name: 'Logout', icon: 'exit_to_app', link: { to: '/logout' } },  -->
          <v-list-item link @click="logout()">
            <v-list-item-icon>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon
        v-if="$store.initialized && $store.loggedin"
        @click="menu = !menu"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>TeamCheck</v-toolbar-title>
      <v-spacer></v-spacer>

      <div id="version">
        <p class="version-text font-weight-thin ml-6">
          {{ versionText }}
        </p>
      </div>

      <v-menu
        tile
        left
        offset-y
        v-model="errorMenu"
        v-if="$store.initialized && $store.loggedin"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text icon v-bind="attrs" v-on="on" class="mr-3">
            <v-icon>notifications</v-icon>
            <v-badge
              v-show="$store.messageCount > 0"
              color="red"
              :content="$store.messageCount"
              bottom
            />
          </v-btn>
        </template>
        <v-list dense min-width="220px">
          <v-list-item v-for="(item, i) in $store.messages" :key="i">
            <v-list-item-icon>
              <v-icon :color="item.error ? 'error' : 'warning'">{{
                item.error ? "report" : "warning"
              }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-badge
              v-show="item.count > 1"
              color="grey"
              :content="item.count"
              offset-x="18"
              offset-y="10"
              bottom
            />
          </v-list-item>
          <v-list-item v-show="$store.messages.length == 0">
            <v-list-item-content>
              <v-list-item-subtitle>Keine Einträge.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <div id="logo">
        <v-img alt="vLead Logo" contain src="@/assets/vlead-logo.png" />
      </div>
    </v-app-bar>

    <v-main id="content">
      <v-container v-if="!$store.initialized" fluid>
        <v-row justify="center" class="mt-5">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-container>
      <router-view v-if="$store.initialized"></router-view>
    </v-main>
    <v-snackbar
      top
      app
      color="rgba(0,0,0,0)"
      elevation="0"
      tile
      v-model="$store.error.show"
      :timeout="5000"
    >
      <v-alert dense type="error">
        <strong v-show="$store.error.source != null" class="text-uppercase"
          >{{ $store.error.source }}:</strong
        >
        {{ $store.error.msg }}
      </v-alert>
    </v-snackbar>

    <v-dialog
      v-model="$pdf.view"
      persistent
      max-width="80%"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card tile height="90vh">
        <v-card-title>
          <span>{{ $pdf.title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="$pdf.view = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-title>
        <embed v-if="$pdf.view" :src="$pdf.file" width="100%" class="pdfview" />
      </v-card>
    </v-dialog>

    <!-- SUCCESS MSG -->
    <v-snackbar v-model="$store.success" color="success" :timeout="5000">
      <span class="text-center text-body-1">{{ $store.successMsg }}</span>
    </v-snackbar>

    <!-- COOKIE BANNER -->
    <v-bottom-sheet
      v-if="!cookiesAccepted"
      :value="!cookiesAccepted"
      persistent
      light
    >
      <v-sheet class="text-center" height="200px">
        <v-container>
          <p class="text-h6">
            Diese Website verwendet Cookies
          </p>
          <p>
            Diese Website nutzt Cookies um einen Benutzerbereich zu realisieren.
            Cookies werden <b>nicht</b> zum Tracking oder Vermarktung verwendet.
          </p>
          <p>
            Mit dem Klick auf den Button "Cookies zulassen" erklären Sie sich
            mit der Verwendung von Cookies einverstanden.
          </p>
          <v-btn @click="acceptCookies()" color="green">Cookies zulassen</v-btn>
          <v-row>
            <v-col>
              <v-btn @click="acceptCookies()" text small disabled
                >Datenschutzerklärung</v-btn
              >
              <v-divider vertical />
              <v-btn @click="acceptCookies()" text small disabled
                >Impressum</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-bottom-sheet>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},

  data: () => ({
    menu: true,
    menuItems: [
      { name: "Startseite", icon: "home", link: "/home" },
      { name: "Teams", icon: "groups", link: "/teams" },
      { name: "Umfragen", icon: "list_alt", link: "/surveys" },
      { name: "Ergebnisse", icon: "insert_chart", link: "/results" },
    ],
    bottomMenuItems: [
      {
        name: "Über",
        icon: "info",
        link: { href: "https://vlead.de/toolbox/teamcheck/", target: "_blank" },
      },
      {
        name: "Kontakt",
        icon: "contact_mail",
        link: { href: "https://vlead.de/kooperation/", target: "_blank" },
      },
    ],
    errorMenu: false,
  }),
  computed: {
    cookiesAccepted() {
      return document.cookie.match(/cookiesAccepted=true;?/) != null;
    },
    versionText() {
      return `Version ${process.env.VUE_APP_VERSION}`;
    },
  },
  methods: {
    acceptCookies() {
      if (!this.cookiesAccepted) {
        document.cookie =
          "cookiesAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        this._computedWatchers.cookiesAccepted.evaluate();
        this.$forceUpdate();
      }
    },
    logout: function() {
      this.call("users/logout").then((res) => {
        if (res.success) {
          this.$store.loggedin = false;
          //this.$router.push("/login");
        }
      });
    },
  },
  watch: {
    "$store.initialized"() {
      if (this.$store.initialized) {
        if (
          this.$store.loggedin &&
          (this.$route.name == "Login" ||
            this.$route.name == "Register" ||
            this.$route.name == "ForgotPassword" ||
            this.$route.name == "NewPassword")
        ) {
          if (this.$route.query.return) {
            this.$router.push(this.$route.query.return);
          } else this.$router.push("/home");
        } else if (
          !this.$store.loggedin &&
          this.$route.name != "Login" &&
          this.$route.name != "Register" &&
          this.$route.name != "ForgotPassword" &&
          this.$route.name != "NewPassword"
        ) {
          this.$router.push({
            name: "Login",
            query: { return: this.$route.path },
          });
        }
      }
    },
    "$store.loggedin": {
      handler: function(newValue, oldValue) {
        if (oldValue && !newValue) this.$router.push("/login");
      },
      deep: true,
    },
    errorMenu() {
      if (this.errorMenu) {
        this.$store.messageCount = 0;
      }
    },
  },
  created() {},
};
</script>

<style>
:root {
  --color-main: #5d9a60;
  --color-main-light: #aeccaf;

  --font-default: 16px;
  --font-small: 14px;
  --font-big: 18px;
  --font-head: 20px;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;

  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-default);
}

.pdfview {
  height: calc(100% - 70px);
}
</style>

<style scoped>
#avatar {
  background-color: #fff;
  width: 80px;
  height: 80px;
  padding: 6px;
  margin: 0px auto;
}

#content {
  /*padding-left: 240px;*/
  background-color: #f5f5f5;
  /*min-height: 100vh;*/
  /*position: relative;*/
}

#logo {
  max-width: 100px;
  height: 40px;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
}

#version {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
}

.version-text {
  font-size: 0.8rem;
  font-weight: bolder;
  margin-bottom: 0;
}
</style>
