<template>
    <v-container class="pa-0">
        <v-row no-gutters>
            
            <v-menu :close-on-content-click="false" transition="slide-y-transition" offset-y bottom right min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="dateFormatted" :rules="rules" v-bind="attrs" v-on="on" :label="label" prepend-icon="today" readonly :required="required" :placeholder="placeholder" :loading="loading"></v-text-field>
                </template>
                <v-date-picker v-model="date" flat scrollable reactive :min="min" :first-day-of-week="1"></v-date-picker>
            </v-menu>
            <v-menu :close-on-content-click="false" transition="slide-y-transition" offset-y bottom right min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="time" :rules="rules" v-bind="attrs" v-on="on" readonly :required="required" :placeholder="placeholder" :loading="loading"></v-text-field>
                </template>
                <v-time-picker v-model="time" format="24hr" flat scrollable :min="minTime"></v-time-picker>
            </v-menu>
            
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: [ "value", "label", "rules", "placeholder", "required", "loading", "min" ], //todo add disabled
        data () {
            return {
                date: this.value != null ? this.getDate(new Date(this.value * 1000)) : this.getDate(new Date(Date.now())),
                time: this.value != null ? this.getTime(new Date(this.value * 1000)) : this.getTime(new Date(Date.now()))
            }
        },
        computed: {
            minTime() {
                if(this.min != null && !(new Date(this.date) > new Date(this.min.split('T')[0]))){
                    console.log("min time update");
                    console.log(this.min);
                    console.log(this.getTime(new Date(this.min)));
                    return this.getTime(new Date(this.min));
                }
                else return null;
            },
            dateFormatted () {
                return this.formatDate(this.date);
            },
            /*value: {
                // getter
                get: function () {
                    return `${this.date} ${this.time}`;
                },
                // setter
                set: function (value) {
                    console.log(`DateTimePicker: ${value}`);
                    if(this.date == null && this.time == null){
                        console.log('DateTimePicker value changed...');
                        console.log(value.split(' '));
                        var valueSplit = value.split(' ');
                        this.date = valueSplit[0];
                        this.time = valueSplit[1];
                    }
                }
            }*/
        },
        methods: {
            getDate(date){
                return date.toISOString().split('T')[0];
            },
            getTime(date){
                return date.toLocaleTimeString().substring(0, 5);
            },
            defaultValue(){
                if(this.value == null){
                    //console.log('DateTimePicker set default value...');
                    //console.log();
                    var date = new Date(Date.now());
                    this.date = this.getDate(date);
                    this.time = this.getTime(date);
                    this.$emit('input', `${Math.floor(date.getTime() / 1000)}`);
                }
                else {
                    this.date = this.getDate(new Date(this.value * 1000));
                    this.time = this.getTime(new Date(this.value * 1000));
                }
            },
            formatDate(date){
                if (!date) return null;

                const [year, month, day] = date.split('-');
                return `${day}.${month}.${year}`;
            }
        },
        watch: {
            value () {
                this.defaultValue();
            },
            date (value) {
                if(this.min != null && new Date(this.min) > new Date(`${this.date} ${this.time}`)){
                    this.time = this.getTime(new Date(this.min));
                }

                this.$emit('input', `${new Date(`${value} ${this.time}`).getTime() / 1000}`);
            },
            time (value) {
                this.$emit('input', `${new Date(`${this.date} ${value}`).getTime() / 1000}`);
            },
            min () {
                console.log("min changed");
                console.log(this.min);

                this.$forceUpdate();
            }
        },
        mounted () {
            //console.log('DateTimePicker mounted...');
            this.defaultValue();

        }
    }
</script>

<style scoped>

</style>