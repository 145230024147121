import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: {
                    base: '#5d9a60',
                    lighten1: '#aeccaf'
                },
                secondary: '#aeccaf',
            },
        },
    },
    icons: {
        iconfont: 'md',
    },
    lang: {
        locales: { de },
        current: 'de',
    },
});
